import fhir4 from 'fhir/r4'
import { cloneDeep } from 'lodash-es'
import produce from 'immer'
import { Questionnaire } from '@visiontree/fhir-engine'

class FormUtils {
  QUESTION_GROUP_LINK_ID_TEMPLATE = '/questionnaire/question-group'
  QUESTION_GROUP_CODE_TEMPLATE = 'question-group:'
  COLUMN_SIZE_URL =
    'https://visiontree.com/fhir/questionnaire/layout#column-size'
  private _DEFAULT_FORM_TEMPLATE: Readonly<fhir4.Questionnaire> = {
    resourceType: 'Questionnaire',
    status: 'draft',
    language: '',
    name: '',
    version: '1',
    item: [],
  }

  private _DEFAULT_QUESTION_GROUP_TEMPLATE: Readonly<fhir4.QuestionnaireItem> =
    {
      type: 'group',
      linkId: '',
      code: [
        {
          system: 'https://fhir.brainlab.com/code-systems/v0.1',
          code: this.QUESTION_GROUP_CODE_TEMPLATE,
        },
      ],
      text: '',
      required: false,
      repeats: false,
      item: [],
      extension: [
        {
          url: this.COLUMN_SIZE_URL,
          valueUnsignedInt: 2,
        },
      ],
    }

  private _DEFAULT_QUESTION_TEMPLATE: Readonly<fhir4.QuestionnaireItem> = {
    linkId: '',
    type: 'string',
    required: false,
    repeats: false,
  }

  createDefaultForm(): Questionnaire {
    return new Questionnaire(this._DEFAULT_FORM_TEMPLATE)
  }

  createQuestionGroup(): fhir4.QuestionnaireItem {
    return cloneDeep(this._DEFAULT_QUESTION_GROUP_TEMPLATE)
  }
  getColumnSizeFromQuestionGroup(
    questionGroup: fhir4.QuestionnaireItem
  ): number | null {
    return (
      questionGroup.extension?.find(
        (extension) => extension.url === this.COLUMN_SIZE_URL
      )?.valueUnsignedInt ?? null
    )
  }

  setColumSizeForQuestionGroup(
    questionGroup: fhir4.QuestionnaireItem,
    newColumnSize: number
  ) {
    return produce(questionGroup, (draft) => {
      const extensionIndex =
        draft.extension?.findIndex(
          (extension) => extension.url === this.COLUMN_SIZE_URL
        ) ?? -1

      if (
        extensionIndex !== -1 &&
        draft.extension &&
        draft.extension[extensionIndex]
      ) {
        draft.extension[extensionIndex].valueUnsignedInt = newColumnSize
      }
    })
  }

  setQuestionGroupCode(
    questionGroup: fhir4.QuestionnaireItem,
    code: string | number
  ): fhir4.QuestionnaireItem {
    return produce(questionGroup, (draft) => {
      if (draft.code) {
        const createdCode =
          (draft.code[0].code || this.QUESTION_GROUP_CODE_TEMPLATE) +
          code.toString().split(' ').join('_')
        draft.code[0].code = createdCode.toLocaleLowerCase()
      }
    })
  }

  getDefaultQuestion() {
    return cloneDeep(this._DEFAULT_QUESTION_TEMPLATE)
  }
}

export default new FormUtils()
