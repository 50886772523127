import { z } from 'zod'
import type { ComponentType } from '@/types'

const componentTypesLiteral = z.literal<ComponentType>
export const componentTypesSchema = z.union([
  componentTypesLiteral('toggle'),
  componentTypesLiteral('date'),
  componentTypesLiteral('slider'),
  componentTypesLiteral('fuzzyDate'),
  componentTypesLiteral('baum'),
  componentTypesLiteral('checkbox'),
  componentTypesLiteral('radio'),
  componentTypesLiteral('numericStepper'),
  componentTypesLiteral('inputText'),
  componentTypesLiteral('multiSlider'),
  componentTypesLiteral('spine'),
  componentTypesLiteral('displayText'),
  componentTypesLiteral('markdown'),
])
