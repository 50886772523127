import fhir4 from 'fhir/r4'
import { Question } from '@/models'
import { z } from 'zod'
import {
  rootFhirLanguageCodeSchema,
  formQuestionSchema,
  uuidSchema,
  themeColorSchema,
} from '@/schemas'

export const nonEmptyString = z.string().trim().min(1)
export const sectionsCountSchema = z.number().int().positive()
export const positiveIndexSchema = z.number().int().nonnegative()
export const prefixStatusSchema = z.string()
export const questionSchema = z.instanceof(Question)
export const createFormOptionsSchema = z.object({
  formName: z.string().trim().min(1),
  sectionsCount: sectionsCountSchema,
  primaryLanguage: rootFhirLanguageCodeSchema,
  additionalLanguages: z.array(rootFhirLanguageCodeSchema).optional(),
  theme: themeColorSchema,
  formNameLanguages: z.any(),
})
export const updateStringFormFieldsOptionsSchema = z.object({
  fieldKey: z
    .literal<keyof fhir4.Questionnaire>('language')
    .or(z.literal<keyof fhir4.Questionnaire>('name')),
  value: z.string().trim().min(1),
})
export const addQuestionsOptionsSchema = z.object({
  sectionIndex: z.number().nonnegative(),
  questionData: formQuestionSchema,
  questionIndex: z.number().nonnegative().optional(),
  renderComponent: z.boolean(),
})
export const updateQuestionsSchema = z.object({
  sectionIndex: positiveIndexSchema,
  questions: questionSchema.array(),
})
export const updateSingleQuestionSchema = z.object({
  updatedQuestion: questionSchema,
  questionId: uuidSchema,
})
export const fontSizeSchema = z.number().int().positive()
export const colorThemeSchema = z.string().trim().min(1)
export const colorSchema = z.string().trim().min(1)
