import fhir4 from 'fhir/r4'
import { type LangTextData, Question, type SetEstensionsParams } from '@/models'
import type {
  RootFhirLanguageCode,
  FontFamilyValidationOptions,
  ThemeColorValidationOptions,
} from '@/schemas'
import {
  type AdditionalLanguagesPayload,
  additionalLanguagesSchema,
  fontFamilyValidationSchema,
  uuidSchema,
} from '@/schemas'
import { FormUtils } from '@/utils'
import produce from 'immer'
import { cloneDeep } from 'lodash-es'
import { defineStore } from 'pinia'
import {
  addQuestionsOptionsSchema,
  createFormOptionsSchema,
  sectionsCountSchema,
  updateQuestionsSchema,
  updateSingleQuestionSchema,
  updateStringFormFieldsOptionsSchema,
  nonEmptyString,
  positiveIndexSchema,
  prefixStatusSchema,
  fontSizeSchema,
  colorThemeSchema,
  colorSchema,
} from './schemas'
import type {
  AddQuestionsOptions,
  CreateFormOptions,
  UpdateQuestionsOptions,
  UpdateSingleQuestionOptions,
  UpdateStringFormFieldsOptions,
} from './types'
import { useFormDataStorage } from '@/composables'
import { Map } from 'immutable'
import { Questionnaire } from '@visiontree/fhir-engine'

function getQuestionGroupByIndex(
  index: number,
  questionGroups: fhir4.QuestionnaireItem[]
) {
  return cloneDeep(questionGroups.at(index))
}

export const useFormStore = defineStore('form', {
  state: () => ({
    questionnaire: null as Questionnaire | null,
    additionalLanguages: [] as RootFhirLanguageCode[],
    activeQuestionGroupIndex: null as number | null,
    colorTheme: null as string | null,
    fontSize: null as number | null,
    fontFamily: {} as FontFamilyValidationOptions,
    primaryColor: null as string | null,
    secondaryColor: null as string | null,
    tertiaryColor: null as string | null,
    fontColor: null as string | null,
    sectionNameLanguages: null as Map<string, string> | null,
    formNameLanguages: null as Map<string, string> | null,
  }),
  getters: {
    colorThemeConfig(): string {
      return <string>this.colorTheme ?? ''
    },
    fontSizeConfig(): number {
      return <number>this.fontSize ?? null
    },
    fontFamilyConfig(): FontFamilyValidationOptions {
      return this.fontFamily ?? {}
    },
    primaryColorConfig(): string {
      return <string>this.primaryColor ?? ''
    },
    secondaryColorConfig(): string {
      return <string>this.secondaryColor ?? ''
    },
    tertiaryColorConfig(): string {
      return <string>this.tertiaryColor ?? ''
    },
    fontColorConfig(): string {
      return <string>this.fontColor ?? ''
    },
    formName(): string {
      return this.questionnaire?.name ?? ''
    },
    primaryLanguage(): string {
      return this.questionnaire?.language ?? ''
    },
    questionGroups(): fhir4.QuestionnaireItem[] | null {
      return this.questionnaire?.item?.map((item) => item) ?? null
    },
    isFormCreated(): boolean {
      return this.questionnaire !== null
    },
    totalSectionsCount(): number {
      return this.questionnaire?.item?.length ?? 0
    },
    activeQuestionGroup(): fhir4.QuestionnaireItem | null {
      if (
        !this.isFormCreated ||
        !this.questionGroups ||
        this.activeQuestionGroupIndex === null
      )
        return null
      return this.questionGroups.at(this.activeQuestionGroupIndex) ?? null
    },
    activeQuestionGroupColumSize(): number | null {
      if (!this.activeQuestionGroup) return null
      return FormUtils.getColumnSizeFromQuestionGroup(this.activeQuestionGroup)
    },
    activeQuestionGroupTitle(): string {
      return this.activeQuestionGroup?.text ?? ''
    },
    activeQuestionPrefixStatus(): string {
      return this.activeQuestionGroup?.prefix ?? ''
    },
    questionsForActiveQuestionGroup(): Question[] | null {
      if (!this.activeQuestionGroup?.item) return null
      return this.activeQuestionGroup?.item.map(
        (question) => new Question({ fhirQuestionData: question })
      )
    },
    getSectionNameLanguages(): Map<string, string> | undefined {
      if (!this.activeQuestionGroup) return undefined
      if (!this?.activeQuestionGroup?._text) return undefined
      let languages = Map<string, string>()

      this.activeQuestionGroup._text?.extension?.forEach((item) => {
        if (
          item?.extension &&
          item.extension[0].valueCode &&
          item.extension[1].valueString
        ) {
          languages = languages.set(
            item.extension[0].valueCode,
            item.extension[1].valueString
          )
        }
      })
      return languages.size > 0 ? languages : undefined
    },
    getFormNameLanguages(): Map<string, string> | undefined {
      if (!this.questionnaire) return undefined
      if (!this?.questionnaire?._name) return undefined
      let languages = Map<string, string>()

      this.questionnaire._name?.extension?.forEach((item) => {
        if (
          item?.extension &&
          item.extension[0].valueCode &&
          item.extension[1].valueString
        ) {
          languages = languages.set(
            item.extension[0].valueCode,
            item.extension[1].valueString
          )
        }
      })
      return languages.size > 0 ? languages : undefined
    },
  },
  actions: {
    setActiveQuestionGroup(groupIndex: number) {
      if (!this.isFormCreated) return console.warn('Form is not created')
      const indexParseResult = positiveIndexSchema.safeParse(groupIndex)
      if (!indexParseResult.success)
        return console.error(indexParseResult.error)
      const expectedGroup = this.questionGroups?.at(groupIndex) ?? null
      if (!expectedGroup)
        return console.warn('Group with index ' + groupIndex + ' is not found')
      this.activeQuestionGroupIndex = groupIndex
    },
    createClinicalForm(options: CreateFormOptions) {
      const parseResult = createFormOptionsSchema.safeParse(options)
      if (!parseResult.success) return console.error(parseResult.error)
      const {
        formName,
        primaryLanguage,
        sectionsCount,
        additionalLanguages,
        theme,
        formNameLanguages,
      } = options
      this.createBareForm()
      this.updateStringFormFields({ fieldKey: 'name', value: formName })
      this.updateStringFormFields({
        fieldKey: 'language',
        value: primaryLanguage,
      })
      this.generateFormGroupsFromSectionsCount(sectionsCount)
      additionalLanguages && this.setAdditionalLanguages(additionalLanguages)
      formNameLanguages.forEach((item: AdditionalLanguagesPayload) => {
        this.updateFormNameTranslate(item)
      })
      theme && this.setTheme(theme)
    },
    createBareForm() {
      this.questionnaire = FormUtils.createDefaultForm()
    },
    updateStringFormFields(options: UpdateStringFormFieldsOptions) {
      if (!this.questionnaire)
        return console.warn('Questionnaire in not defined')

      const optionsParseResult =
        updateStringFormFieldsOptionsSchema.safeParse(options)
      if (!optionsParseResult.success)
        return console.error(optionsParseResult.error)

      const { fieldKey, value } = options
      if (fieldKey === 'language' || fieldKey === 'name') {
        this.questionnaire[fieldKey] = value
      }
    },
    setAdditionalLanguages(additionalLanguagesPayload: RootFhirLanguageCode[]) {
      const parseResult = createFormOptionsSchema.shape.additionalLanguages
        .unwrap()
        .safeParse(additionalLanguagesPayload)
      if (!parseResult.success) return console.error(parseResult.error)

      this.additionalLanguages = [...additionalLanguagesPayload]
    },
    setTheme(themeData: ThemeColorValidationOptions) {
      if (themeData) {
        themeData.name && this.setColorTheme(themeData.name)
        themeData.fontSize && this.setFontSize(themeData.fontSize)
        themeData.fontFamily && this.setFontFamily(themeData.fontFamily)
        if (themeData.custom) {
          themeData.custom.primaryColor &&
            this.setPrimaryColor(themeData.custom.primaryColor)
          themeData.custom.secondaryColor &&
            this.setSecondaryColor(themeData.custom.secondaryColor)
          themeData.custom.tertiaryColor &&
            this.setTertiaryColor(themeData.custom.tertiaryColor)
          themeData.custom.fontColor &&
            this.setFontColor(themeData.custom.fontColor)
        }
      }
    },
    setFontSize(value: number) {
      const parseResult = fontSizeSchema.safeParse(value)
      if (!parseResult.success) return console.error(parseResult.error)
      if (value) {
        this.fontSize = value
      }
    },
    setFontFamily(value: FontFamilyValidationOptions) {
      const parseResult = fontFamilyValidationSchema.safeParse(value)
      if (!parseResult.success) return console.error(parseResult.error)
      if (value) {
        this.fontFamily = value
      }
    },
    setColorTheme(value: string) {
      const parseResult = colorThemeSchema.safeParse(value)
      if (!parseResult.success) return console.error(parseResult.error)
      if (value) {
        this.colorTheme = value
      }
    },
    setPrimaryColor(value: string) {
      const parseResult = colorSchema.safeParse(value)
      if (!parseResult.success) return console.error(parseResult.error)
      if (value) {
        this.primaryColor = value
      }
    },
    setSecondaryColor(value: string) {
      const parseResult = colorSchema.safeParse(value)
      if (!parseResult.success) return console.error(parseResult.error)
      if (value) {
        this.secondaryColor = value
      }
    },
    setTertiaryColor(value: string) {
      const parseResult = colorSchema.safeParse(value)
      if (!parseResult.success) return console.error(parseResult.error)
      if (value) {
        this.tertiaryColor = value
      }
    },
    setFontColor(value: string) {
      const parseResult = colorSchema.safeParse(value)
      if (!parseResult.success) return console.error(parseResult.error)
      if (value) {
        this.fontColor = value
      }
    },
    generateFormGroupsFromSectionsCount(sectionsCount: number) {
      const { getFormDataFromLocalStorage } = useFormDataStorage()
      const storageData = getFormDataFromLocalStorage()
      if (!this.questionnaire)
        return console.warn('Questionnaire in not defined')
      const parseResult = sectionsCountSchema.safeParse(sectionsCount)
      if (!parseResult.success) return console.error(parseResult.error)
      const formGroups: fhir4.QuestionnaireItem[] = []
      for (let index = 1; index <= sectionsCount; index++) {
        const questionGroup = FormUtils.createQuestionGroup()
        questionGroup.linkId = `${FormUtils.QUESTION_GROUP_LINK_ID_TEMPLATE}:${index}`
        questionGroup.text = 'SECTION NAME'
        if (
          storageData &&
          storageData.questionnaire &&
          storageData.questionnaire.item &&
          storageData.questionnaire.item[index - 1]
        ) {
          questionGroup.item =
            storageData.questionnaire.item[index - 1].item || []
        }
        const questionGroupWithCode = FormUtils.setQuestionGroupCode(
          questionGroup,
          index
        )
        formGroups.push({ ...questionGroupWithCode })
      }

      this.questionnaire.item = formGroups
    },
    addQuestionsToQuestionGroup(options: AddQuestionsOptions) {
      if (!this.isFormCreated) return console.warn('Form is not created')
      const parseResult = addQuestionsOptionsSchema.safeParse(options)
      if (!parseResult.success) return console.error(parseResult.error)

      const { questionData, sectionIndex, questionIndex } = parseResult.data

      const questionGroupByIndex = getQuestionGroupByIndex(
        sectionIndex,
        this.questionnaire?.item ?? []
      )
      if (!questionGroupByIndex)
        return console.error(
          'the index for a section that does not exist is set'
        )

      const linkIdIndex =
        questionIndex !== undefined
          ? questionIndex
          : questionGroupByIndex?.item
          ? questionGroupByIndex.item.length
          : 0

      const formQuestionLinkIds =
        this.questionGroups
          ?.map(
            (questionGroup) =>
              questionGroup.item?.map((item) => item?.linkId ?? '') ?? []
          )
          .flat() ?? []

      const questionNewLinkId = `${String(questionData.linkId)}-${String(
        linkIdIndex
      )}`

      if (
        formQuestionLinkIds.findIndex(
          (linkId) => linkId === questionNewLinkId
        ) !== -1
      ) {
        console.warn(`LinkId ${questionNewLinkId} already exist`)
        this.addQuestionsToQuestionGroup({
          ...options,
          questionIndex: linkIdIndex + 1,
        })
        return
      }

      const createdFhirQuestion = new Question().createQuestion(
        produce(questionData, (draft) => {
          if (options.renderComponent) {
            draft.linkId = questionNewLinkId
          }
        })
      ).fhirQuestion

      if (!createdFhirQuestion) {
        return console.warn("Can't create FhirQuestion")
      }

      if (options.renderComponent) {
        const updatedQuestionGroup = produce(questionGroupByIndex, (draft) => {
          draft.item?.push({ ...createdFhirQuestion })
        })

        if (this.questionnaire?.item) {
          this.questionnaire.item[sectionIndex] = updatedQuestionGroup
        }
      } else {
        return createdFhirQuestion
      }
      const { saveQuestionFormToLocalStorage } = useFormDataStorage()
      saveQuestionFormToLocalStorage(options)
    },

    updateQuestionsForQuestionGroup(options: UpdateQuestionsOptions) {
      if (!this.isFormCreated) return console.warn('Form is not created')
      const parseResult = updateQuestionsSchema.safeParse(options)
      if (!parseResult.success)
        return console.error(parseResult.error.flatten())
      const { questions, sectionIndex } = parseResult.data
      const questionGroupByIndex = getQuestionGroupByIndex(
        sectionIndex,
        this.questionnaire?.item ?? []
      )
      if (!questionGroupByIndex)
        return console.error(
          'the index for a section that does not exist is set'
        )
      const updatedQuestionGroup = produce(questionGroupByIndex, (draft) => {
        draft.item = questions
          .map((question) => question.fhirQuestion)
          .filter(
            (fhirQuestion): fhirQuestion is fhir4.QuestionnaireItem =>
              fhirQuestion !== null
          )
      })
      if (this.questionnaire?.item) {
        this.questionnaire.item[sectionIndex] = updatedQuestionGroup
      }
    },
    updateSingleQuestionInQuestionGroup(options: UpdateSingleQuestionOptions) {
      if (!this.isFormCreated) return console.warn('Form is not created')
      const parseResult = updateSingleQuestionSchema.safeParse(
        cloneDeep(options)
      )
      if (!parseResult.success)
        return console.error(parseResult.error.flatten())

      const { updatedQuestion, questionId } = parseResult.data
      const updatedQuestionFhirData = updatedQuestion.fhirQuestion
      if (!updatedQuestionFhirData) {
        return console.warn('Something wrong with Question instance')
      }

      const questionIndex = this.questionsForActiveQuestionGroup?.findIndex(
        (question) => question.formQuestionData?.id === questionId
      )
      if (questionIndex === undefined || questionIndex === -1) {
        return console.warn('Can not find question with id ' + questionId)
      }

      const updatedQuestionGroup = produce(
        cloneDeep(this.activeQuestionGroup),
        (draft) => {
          if (draft?.item) {
            draft.item[questionIndex] = updatedQuestionFhirData
          }
        }
      )
      if (
        updatedQuestionGroup &&
        this.questionnaire?.item &&
        this.activeQuestionGroupIndex !== null &&
        this.questionnaire.item[this.activeQuestionGroupIndex]
      ) {
        this.questionnaire.item[this.activeQuestionGroupIndex] =
          updatedQuestionGroup
      }
    },
    loadSavedQuestionnaire(options: { questionnaire: fhir4.Questionnaire }) {
      const { questionnaire } = options
      if (!questionnaire) {
        return
      }
      this.questionnaire = new Questionnaire(questionnaire)
    },
    setColumSizeForActiveQuestionGroup(newColumnSize: number) {
      if (!this.isFormCreated || !this.activeQuestionGroup)
        return console.warn('Form is not created')

      const updateActiveQuestionGroup = FormUtils.setColumSizeForQuestionGroup(
        cloneDeep(this.activeQuestionGroup),
        newColumnSize
      )

      if (
        this.activeQuestionGroupIndex !== null &&
        this.questionnaire?.item &&
        this.questionnaire.item[this.activeQuestionGroupIndex]
      ) {
        this.questionnaire.item[this.activeQuestionGroupIndex] =
          updateActiveQuestionGroup
      }
    },
    deleteSingleQuestionFromActiveQuestionGroup(questionId: string) {
      if (!this.isFormCreated) return console.warn('Form is not created')

      const parseUuidResult = uuidSchema.safeParse(questionId)
      if (!parseUuidResult.success) {
        return console.error(parseUuidResult.error.flatten())
      }
      const id = parseUuidResult.data
      const questionIndex = this.questionsForActiveQuestionGroup?.findIndex(
        (question) => question.formQuestionData?.id === id
      )
      if (questionIndex === undefined || questionIndex === -1) {
        return console.warn('Can not find question with id ' + id)
      }
      const updatedQuestionGroup = produce(
        this.activeQuestionGroup,
        (draft) => {
          if (draft?.item) {
            draft.item.splice(questionIndex, 1)
          }
        }
      )
      if (
        this.activeQuestionGroupIndex !== null &&
        updatedQuestionGroup &&
        this.questionnaire?.item &&
        this.questionnaire.item[this.activeQuestionGroupIndex]
      ) {
        this.questionnaire.item[this.activeQuestionGroupIndex] =
          updatedQuestionGroup
      }
    },
    updateTitleForActiveQuestionGroup(newTitle: string) {
      if (!this.isFormCreated) return console.warn('Form is not created')
      const stringParseResult = nonEmptyString.safeParse(newTitle)
      if (!stringParseResult.success)
        return console.error(stringParseResult.error.flatten())
      const updatedQuestionGroup = produce(
        this.activeQuestionGroup,
        (draft) => {
          if (!draft) return
          draft.text = stringParseResult.data
        }
      )
      if (
        this.activeQuestionGroupIndex !== null &&
        updatedQuestionGroup &&
        this.questionnaire?.item &&
        this.questionnaire.item[this.activeQuestionGroupIndex]
      ) {
        this.questionnaire.item[this.activeQuestionGroupIndex] =
          updatedQuestionGroup
      }
    },
    _getBareLanguageText(): LangTextData {
      return cloneDeep(Question.LANG_TEXT_TEMPLATE)
    },
    setExtensionAndLanguages(params: SetEstensionsParams) {
      if (!params.additionalLanguageItem?._text) {
        params.additionalLanguageItem._text = { extension: [] }
      }
      const alreadyExistingLanguage =
        params.additionalLanguageItem?._text.extension?.find((item) => {
          if (item?.extension?.length) {
            return item.extension[0].valueCode === params.valueCode
          }
        })
      if (alreadyExistingLanguage && alreadyExistingLanguage.extension) {
        alreadyExistingLanguage.extension[1].valueString = params.valueString
        return
      }
      params.additionalLanguageItem?._text.extension?.push(
        params.languageExtension
      )
    },
    updateTitleTranslateForActiveQuestionGroup(
      options: AdditionalLanguagesPayload
    ) {
      if (!this.isFormCreated) return console.warn('Form is not created')
      const parseResult = additionalLanguagesSchema.safeParse(options)
      if (!parseResult.success)
        return console.error(parseResult.error.flatten())
      const { valueCode, valueString } = parseResult.data
      const languageExtension = this._getBareLanguageText()
      languageExtension.extension[0].valueCode = valueCode
      languageExtension.extension[1].valueString = valueString
      const updatedQuestionGroup = produce(
        this.activeQuestionGroup,
        (draft) => {
          if (!draft) return
          this.setExtensionAndLanguages({
            additionalLanguageItem: draft,
            languageExtension,
            valueCode,
            valueString,
          })
        }
      )
      if (
        this.activeQuestionGroupIndex !== null &&
        updatedQuestionGroup &&
        this.questionnaire?.item &&
        this.questionnaire.item[this.activeQuestionGroupIndex]
      ) {
        this.questionnaire.item[this.activeQuestionGroupIndex] =
          updatedQuestionGroup
      }
    },
    updateFormNameTranslate(options: AdditionalLanguagesPayload) {
      if (!this.isFormCreated) return console.warn('Form is not created')
      const parseResult = additionalLanguagesSchema.safeParse(options)
      if (!parseResult.success)
        return console.error(parseResult.error.flatten())
      const { valueCode, valueString } = parseResult.data
      const languageExtension = this._getBareLanguageText()
      languageExtension.extension[0].valueCode = valueCode
      languageExtension.extension[1].valueString = valueString

      if (!this.questionnaire) return
      if (!this.questionnaire._name) {
        this.questionnaire._name = { extension: [] }
      }
      const alreadyExistingLanguage = this.questionnaire?._name.extension?.find(
        (item) => {
          if (item?.extension?.length) {
            return item.extension[0].valueCode === valueCode
          }
        }
      )
      if (alreadyExistingLanguage && alreadyExistingLanguage.extension) {
        alreadyExistingLanguage.extension[1].valueString = valueString
        return
      }
      this.questionnaire?._name.extension?.push(languageExtension)
    },
    updatePrefixStatusForActiveQuestionGroup(newStatus: string) {
      if (!this.isFormCreated) return console.warn('Form is not created')
      const prefixParseResult = prefixStatusSchema.safeParse(newStatus)
      if (!prefixParseResult.success)
        return console.error(prefixParseResult.error.flatten())
      const updatedQuestionGroup = produce(
        this.activeQuestionGroup,
        (draft) => {
          if (!draft) return
          draft.prefix = prefixParseResult.data
        }
      )
      if (
        this.activeQuestionGroupIndex !== null &&
        updatedQuestionGroup &&
        this.questionnaire?.item &&
        this.questionnaire.item[this.activeQuestionGroupIndex]
      ) {
        this.questionnaire.item[this.activeQuestionGroupIndex] =
          updatedQuestionGroup
      }
    },
  },
})
