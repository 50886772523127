import { defineNuxtPlugin, useRuntimeConfig } from 'nuxt/app'
import { datadogRum } from '@datadog/browser-rum'

/**
 * Enable Datadog RUM browser monitoring.
 *
 * @see https://docs.datadoghq.com/real_user_monitoring/browser/
 */
export default defineNuxtPlugin(() => {
  const config = useRuntimeConfig()

  if (!config.public.datadogEnable) {
    return
  }

  datadogRum.init({
    site: 'datadoghq.com',
    service: 'live-preview',
    telemetrySampleRate: 0, // Opt out of sending SDK logs directly to Datadog
    applicationId: config.public.datadogApplicationId,
    clientToken: config.public.datadogClientToken,
    env: config.public.datadogEnv,
    sessionSampleRate: config.public.datadogSessionSampleRate,
    sessionReplaySampleRate: config.public.datadogSessionReplaySampleRate,
    trackUserInteractions: config.public.datadogTrackUserInteractions,
    trackResources: config.public.datadogTrackResources,
    trackFrustrations: config.public.datadogTrackFrustrations,
    trackLongTasks: config.public.datadogTrackLongTasks,
    defaultPrivacyLevel: config.public
      .datadogDefaultPrivacyLevel as 'mask-user-input', // Casting required until https://github.com/nuxt/nuxt/issues/20379 is resolved
  })

  if (config.public.datadogSessionReplaySampleRate) {
    datadogRum.startSessionReplayRecording()
  }
})
