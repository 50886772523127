import { default as buildertaTi6NzY29Meta } from "/home/runner/work/mfx-framework/mfx-framework/apps/form-builder/pages/builder.vue?macro=true";
import { default as clinical_45form_45configurationYg3okGgCrLMeta } from "/home/runner/work/mfx-framework/mfx-framework/apps/form-builder/pages/clinical-form-configuration.vue?macro=true";
import { default as indexTU1nsKfczqMeta } from "/home/runner/work/mfx-framework/mfx-framework/apps/form-builder/pages/index.vue?macro=true";
import { default as previewQ9hJPA5272Meta } from "/home/runner/work/mfx-framework/mfx-framework/apps/form-builder/pages/preview.vue?macro=true";
export default [
  {
    name: buildertaTi6NzY29Meta?.name ?? "builder",
    path: buildertaTi6NzY29Meta?.path ?? "/builder",
    meta: buildertaTi6NzY29Meta || {},
    alias: buildertaTi6NzY29Meta?.alias || [],
    redirect: buildertaTi6NzY29Meta?.redirect || undefined,
    component: () => import("/home/runner/work/mfx-framework/mfx-framework/apps/form-builder/pages/builder.vue").then(m => m.default || m)
  },
  {
    name: clinical_45form_45configurationYg3okGgCrLMeta?.name ?? "clinical-form-configuration",
    path: clinical_45form_45configurationYg3okGgCrLMeta?.path ?? "/clinical-form-configuration",
    meta: clinical_45form_45configurationYg3okGgCrLMeta || {},
    alias: clinical_45form_45configurationYg3okGgCrLMeta?.alias || [],
    redirect: clinical_45form_45configurationYg3okGgCrLMeta?.redirect || undefined,
    component: () => import("/home/runner/work/mfx-framework/mfx-framework/apps/form-builder/pages/clinical-form-configuration.vue").then(m => m.default || m)
  },
  {
    name: indexTU1nsKfczqMeta?.name ?? "index",
    path: indexTU1nsKfczqMeta?.path ?? "/",
    meta: indexTU1nsKfczqMeta || {},
    alias: indexTU1nsKfczqMeta?.alias || [],
    redirect: indexTU1nsKfczqMeta?.redirect || undefined,
    component: () => import("/home/runner/work/mfx-framework/mfx-framework/apps/form-builder/pages/index.vue").then(m => m.default || m)
  },
  {
    name: previewQ9hJPA5272Meta?.name ?? "preview",
    path: previewQ9hJPA5272Meta?.path ?? "/preview",
    meta: previewQ9hJPA5272Meta || {},
    alias: previewQ9hJPA5272Meta?.alias || [],
    redirect: previewQ9hJPA5272Meta?.redirect || undefined,
    component: () => import("/home/runner/work/mfx-framework/mfx-framework/apps/form-builder/pages/preview.vue").then(m => m.default || m)
  }
]