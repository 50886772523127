import { v4 } from 'uuid'
import { cloneDeep } from 'lodash-es'
import { enableWhenOperatorsSchema } from '@/schemas'
import produce from 'immer'
import { z } from 'zod'

export const addEnableWhenItemSchema = z.object({
  whenComponentType: z.string().optional(),
  whenComponentId: z.string().optional(),
  questionLinkId: z.string(),
  conditionalValue: z.boolean().or(z.string()),
  operator: enableWhenOperatorsSchema,
})
export type AddEnableWhenItemOptions = z.infer<typeof addEnableWhenItemSchema>

export type EnableWhenFormData = {
  id: string
} & Omit<AddEnableWhenItemOptions, 'conditionalValue'> & {
    conditionalValue: AddEnableWhenItemOptions['conditionalValue'] | null
    whenComponentType: AddEnableWhenItemOptions['whenComponentType'] | undefined
    whenComponentId: AddEnableWhenItemOptions['whenComponentId'] | null
  }

interface QuestionnaireItemEnableWhen
  extends fhir4.QuestionnaireItemEnableWhen {
  whenComponentType?: AddEnableWhenItemOptions['whenComponentType']
  whenComponentId?: AddEnableWhenItemOptions['whenComponentId']
}

export class EnableWhen {
  static EnableWhenItemTemplate: QuestionnaireItemEnableWhen = {
    question: '',
    whenComponentType: '',
    whenComponentId: '',
    operator: '=',
  }
  static AnswerCodingSystemUrl =
    'https://visiontree.com/fhir/questionnaire/logic/'
  static AnswerCodingTemplate: Readonly<fhir4.Coding> = {
    system: EnableWhen.AnswerCodingSystemUrl,
    code: '',
  }

  private _fhirEnableWhenData: QuestionnaireItemEnableWhen | null = null

  constructor(options?: { fhirData: fhir4.QuestionnaireItemEnableWhen }) {
    if (options) {
      this._fhirEnableWhenData = cloneDeep(options.fhirData)
    }
  }
  private _getBareAnswerCodingObject(): fhir4.Coding {
    return cloneDeep(EnableWhen.AnswerCodingTemplate)
  }

  get enableWhenFhir(): fhir4.QuestionnaireItemEnableWhen | null {
    return this._fhirEnableWhenData
  }

  get enableWhenFormData(): EnableWhenFormData | null {
    if (this._fhirEnableWhenData === null) return null

    return {
      id: this._fhirEnableWhenData.id ?? '',
      operator: this._fhirEnableWhenData.operator,
      whenComponentType: this._fhirEnableWhenData.whenComponentType,
      whenComponentId: this._fhirEnableWhenData.whenComponentId,
      questionLinkId: this._fhirEnableWhenData.question,
      conditionalValue: this._fhirEnableWhenData?.answerCoding
        ? this._fhirEnableWhenData.answerCoding?.code ?? ''
        : this._fhirEnableWhenData.answerBoolean !== undefined
        ? this._fhirEnableWhenData.answerBoolean
        : null,
    }
  }

  addEnableWhenItem(options: AddEnableWhenItemOptions) {
    const optionsParseResult = addEnableWhenItemSchema.safeParse(options)
    if (!optionsParseResult.success) {
      console.error(optionsParseResult.error.flatten())
      return null
    }
    const {
      operator,
      conditionalValue,
      questionLinkId,
      whenComponentType,
      whenComponentId,
    } = optionsParseResult.data
    const enableWhenItem: QuestionnaireItemEnableWhen = {
      id: v4(),
      question: questionLinkId,
      operator: operator,
      whenComponentType: whenComponentType || '',
      whenComponentId: whenComponentId || '',
    }
    typeof conditionalValue === 'boolean'
      ? (enableWhenItem.answerBoolean = conditionalValue)
      : (enableWhenItem.answerCoding = {
          ...this._getBareAnswerCodingObject(),
          code: conditionalValue,
        })
    this._fhirEnableWhenData = cloneDeep(enableWhenItem)
    return this._fhirEnableWhenData
  }

  updateEnableWhenItem(options: AddEnableWhenItemOptions) {
    if (this._fhirEnableWhenData === null) {
      return null
    }
    const optionsParseResult = addEnableWhenItemSchema.safeParse(options)
    if (!optionsParseResult.success) {
      console.error(optionsParseResult.error.format())
      return null
    }
    const {
      operator,
      conditionalValue,
      questionLinkId,
      whenComponentType,
      whenComponentId,
    } = optionsParseResult.data

    this._fhirEnableWhenData = produce(
      cloneDeep(this._fhirEnableWhenData),
      (draft) => {
        draft.whenComponentType = whenComponentType || ''
        draft.whenComponentId = whenComponentId || ''
        draft.operator = operator
        draft.question = questionLinkId
        if (!draft.answerCoding) {
          draft.answerCoding = { ...this._getBareAnswerCodingObject() }
        }
        draft.answerCoding.code = conditionalValue.toString()
      }
    )
    return this._fhirEnableWhenData
  }
}

export const updateEnableWhenItemsSchema = z.instanceof(EnableWhen).array()
export type UpdateEnableWhenPayload = z.infer<
  typeof updateEnableWhenItemsSchema
>
