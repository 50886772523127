import { cloneDeep } from 'lodash-es'
import { v4 } from 'uuid'
import { z } from 'zod'

export type AnswerOptionFormData = {
  code: string
  displayText: string
  isInitialSelected?: boolean
  id: string
}

export const answerOptionSchema = z.object({
  displayedText: z.string(),
  code: z.string(),
  isInitialSelected: z.boolean().optional(),
  id: z.string().optional(),
})
export type AnswerOptionPayload = z.infer<typeof answerOptionSchema>

export class AnswerOption {
  static ANSWER_OPTION_TEMPLATE: Readonly<fhir4.QuestionnaireItemAnswerOption> =
    {
      valueCoding: {
        system: 'https://fhir.brainlab.com/code-systems/v0.1',
        code: '',
        display: '',
      },
      initialSelected: false,
    }

  private _fhirAnswerOption: fhir4.QuestionnaireItemAnswerOption | null = null

  constructor(options?: { fhirData: fhir4.QuestionnaireItemAnswerOption }) {
    if (options?.fhirData) {
      const { fhirData } = options
      if (fhirData && Object.keys(fhirData).length) {
        this._fhirAnswerOption = cloneDeep(fhirData)
      }
    }
  }

  private _getBareAnswerOption(): fhir4.QuestionnaireItemAnswerOption {
    return cloneDeep(AnswerOption.ANSWER_OPTION_TEMPLATE)
  }

  createAnswerOption(options: AnswerOptionPayload) {
    if (!options) {
      throw new Error('Options must be provided')
    }
    const parseResult = answerOptionSchema.parse(options)
    const { displayedText, code, isInitialSelected, id } = parseResult
    const answerOption = this._getBareAnswerOption()
    if (!answerOption.valueCoding) answerOption.valueCoding = {}
    answerOption.valueCoding.code = code || displayedText
    answerOption.valueCoding.display = displayedText
    answerOption.initialSelected = !!isInitialSelected
    answerOption.id = id ? id : v4()
    this._fhirAnswerOption = answerOption
    return this
  }

  get fhirAnswerOption() {
    return this._fhirAnswerOption
  }

  get formData(): AnswerOptionFormData | null {
    if (this._fhirAnswerOption === null) return null
    return {
      code: this._fhirAnswerOption?.valueCoding?.code ?? '',
      displayText: this._fhirAnswerOption?.valueCoding?.display ?? '',
      id: this._fhirAnswerOption?.id ?? '',
    }
  }

  updateAnswerOption(options: AnswerOptionPayload) {
    if (!this._fhirAnswerOption) {
      return console.warn('No answer option')
    }
    const optionsParseResult = answerOptionSchema.safeParse(options)
    if (!optionsParseResult.success) {
      return console.error(optionsParseResult.error.flatten())
    }
    this.createAnswerOption({ ...optionsParseResult.data })
  }
}
