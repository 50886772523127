import plugin_vue3_UvKrSBKXLB from "/home/runner/work/mfx-framework/mfx-framework/node_modules/.pnpm/@pinia+nuxt@0.4.10_typescript@4.9.5_vue@3.3.2/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/home/runner/work/mfx-framework/mfx-framework/apps/form-builder/.nuxt/components.plugin.mjs";
import unhead_NEOG2gsbPO from "/home/runner/work/mfx-framework/mfx-framework/node_modules/.pnpm/nuxt@3.4.3_@types+node@18.16.12_eslint@8.40.0_typescript@4.9.5/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_dt7Emz7UCW from "/home/runner/work/mfx-framework/mfx-framework/node_modules/.pnpm/nuxt@3.4.3_@types+node@18.16.12_eslint@8.40.0_typescript@4.9.5/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import prefetch_client_2ImU0IG2yX from "/home/runner/work/mfx-framework/mfx-framework/node_modules/.pnpm/nuxt@3.4.3_@types+node@18.16.12_eslint@8.40.0_typescript@4.9.5/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import composition_OjyZu80Kut from "/home/runner/work/mfx-framework/mfx-framework/node_modules/.pnpm/@nuxtjs+i18n@8.0.0-beta.11_vue@3.3.2/node_modules/@nuxtjs/i18n/dist/runtime/plugins/composition.mjs";
import i18n_4QQnflVwHn from "/home/runner/work/mfx-framework/mfx-framework/node_modules/.pnpm/@nuxtjs+i18n@8.0.0-beta.11_vue@3.3.2/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import chunk_reload_client_A9DkYpeLjG from "/home/runner/work/mfx-framework/mfx-framework/node_modules/.pnpm/nuxt@3.4.3_@types+node@18.16.12_eslint@8.40.0_typescript@4.9.5/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import payload_client_pfJPITnWI1 from "/home/runner/work/mfx-framework/mfx-framework/node_modules/.pnpm/nuxt@3.4.3_@types+node@18.16.12_eslint@8.40.0_typescript@4.9.5/node_modules/nuxt/dist/app/plugins/payload.client.js";
import datadog_client_cfAAU11B0P from "/home/runner/work/mfx-framework/mfx-framework/apps/form-builder/plugins/datadog.client.ts";
export default [
  plugin_vue3_UvKrSBKXLB,
  components_plugin_KR1HBZs4kY,
  unhead_NEOG2gsbPO,
  router_dt7Emz7UCW,
  prefetch_client_2ImU0IG2yX,
  composition_OjyZu80Kut,
  i18n_4QQnflVwHn,
  chunk_reload_client_A9DkYpeLjG,
  payload_client_pfJPITnWI1,
  datadog_client_cfAAU11B0P
]