import { z } from 'zod'

export const rootFhirLanguageCodeSchema = z.union([
  z.literal('da'),
  z.literal('de'),
  z.literal('en-US'),
  z.literal('es'),
  z.literal('fr'),
  z.literal('sv'),
  z.literal('it'),
])
export type RootFhirLanguageCode = z.infer<typeof rootFhirLanguageCodeSchema>
