import { any, z } from 'zod'
import type { ComponentType } from '@/types'
import {
  numberRangeMaxValue,
  numberRangeMinValue,
} from '@/constants/validationList'
import { AnswerOption } from '@/models/AnswerOption'

export const questionTypeLiteral = z.literal<fhir4.QuestionnaireItem['type']>
export const displayBehaviorLiteral = z.literal<
  Required<fhir4.QuestionnaireItem>['enableBehavior']
>
export const nonEmptyString = z.string().trim().min(1)
export const uuidSchema = z.string().uuid()
export const displayConditionItemSchema = z.object({
  sourceFieldLinkId: z.string(),
})
export const componentTypeLiteral = z.literal<ComponentType>

export const formQuestionSchema = z.object({
  linkId: nonEmptyString,
  label: nonEmptyString,
  required: z.boolean(),
  componentType: z.union([
    componentTypeLiteral('date'),
    componentTypeLiteral('slider'),
    componentTypeLiteral('checkbox'),
    componentTypeLiteral('radio'),
    componentTypeLiteral('numericStepper'),
    componentTypeLiteral('inputText'),
    componentTypeLiteral('toggle'),
    componentTypeLiteral('multiSlider'),
    componentTypeLiteral('spine'),
    componentTypeLiteral('displayText'),
    componentTypeLiteral('markdown'),
    componentTypeLiteral('baum'),
  ]),
  repeats: z.boolean(),
  readOnly: z.boolean(),
  columnPositionIndex: z.number().nonnegative(),
})
export const addTooltipOptionsSchema = z.object({
  tooltipValue: z.string().min(0),
  questionLinkId: z.string().min(1),
})

export const addTooltipRemoveSchema = z.object({
  questionLinkId: z.string().min(1),
})

export const additionalLanguagesSchema = z.object({
  valueCode: nonEmptyString,
  valueString: z.string().trim(),
})

export const multiSliderLabelPayload = z.object({
  id: z.string().trim(),
  valueString: z.string().trim(),
})

export const baumPayload = z.object({
  componentIndex: z.string().trim(),
  text: z.string().trim(),
})

export const baumOptions = z.object({
  text: z.string().trim().optional(),
  item: any().optional(),
})

export type BaumType = z.infer<typeof baumOptions>

export type BaumPayloadType = z.infer<typeof baumPayload>

export type MultiSliderLabelType = z.infer<typeof multiSliderLabelPayload>

export const multiSliderLabelLanguagesSchema = z.object({
  valueCode: nonEmptyString,
  sliders: multiSliderLabelPayload.array(),
})

export type MultiSliderLabelLanguagesPayload = z.infer<
  typeof multiSliderLabelLanguagesSchema
>

export const answerOptionsLanguagesSchema = z.object({
  valueCode: nonEmptyString,
  answerOptions: z.instanceof(AnswerOption).array(),
})

export type AnswerOptionsLanguagesPayload = z.infer<
  typeof answerOptionsLanguagesSchema
>

export type AdditionalLanguagesPayload = z.infer<
  typeof additionalLanguagesSchema
>

export const enableWhenOperatorLiteral = z.literal<
  fhir4.QuestionnaireItemEnableWhen['operator']
>
export const enableWhenOperatorsSchema = z.union([
  enableWhenOperatorLiteral('='),
  enableWhenOperatorLiteral('!='),
  enableWhenOperatorLiteral('<'),
  enableWhenOperatorLiteral('<='),
  enableWhenOperatorLiteral('>'),
  enableWhenOperatorLiteral('>='),
  enableWhenOperatorLiteral('exists'),
])
export type EnableWhenOperators = z.infer<typeof enableWhenOperatorsSchema>

export const enableBehaviorLiteral = z.literal<
  NonNullable<fhir4.QuestionnaireItem['enableBehavior']>
>
export const enableBehaviorSchema = z.union([
  enableBehaviorLiteral('all'),
  enableBehaviorLiteral('any'),
])

export const primaryConditionSchema = z.boolean()
export type EnableBehavior = z.infer<typeof enableBehaviorSchema>

export const validationKeys = z.union([
  z.literal('numbers'),
  z.literal('email'),
  z.literal('text'),
  z.literal('phone'),
])

export type ValidationKey = z.infer<typeof validationKeys>

const numberPreprocess = (noZero = false) =>
  z.preprocess((a) => {
    if (a === null || a === '' || a === undefined) return null
    const parseValue = Number(z.string().or(z.number()).parse(a))
    return noZero && parseValue === 0 ? null : parseValue
  }, z.number().min(numberRangeMinValue).max(numberRangeMaxValue).optional())

export const numberRangeValidationSchema = z.object({
  allowDecimal: z.boolean(),
  minValue: numberPreprocess(true),
  maxValue: numberPreprocess(),
})

export const integerNumberSchema = z.number().int()

export type NumberRangeValidationOptions = z.infer<
  typeof numberRangeValidationSchema
>

export const themeColorSchema = z.object({
  custom: z
    .object({
      fontColor: z.string().trim(),
      primaryColor: z.string().trim(),
      secondaryColor: z.string().trim(),
      tertiaryColor: z.string().trim(),
    })
    .optional(),
  name: z.string().trim(),
  fontFamily: z.object({
    id: z.number().nonnegative().optional(),
    name: z.string().trim().optional(),
  }),
  fontSize: z.number().nonnegative(),
})
export type ThemeColorValidationOptions = z.infer<typeof themeColorSchema>

export const fontFamilyValidationSchema = z.object({
  id: z.number().nonnegative().optional(),
  name: z.string().trim().optional(),
})
export type FontFamilyValidationOptions = z.infer<
  typeof fontFamilyValidationSchema
>
