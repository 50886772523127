import { onMounted } from 'vue'
import { useFormStore, createFormOptionsSchema } from '@/stores'
import { localStorageKeys } from '@/constants'
import { z } from 'zod'
import type { CreateFormOptions } from '@/stores'
import type {
  RootFhirLanguageCode,
  ThemeColorValidationOptions,
} from '@/schemas'
import type { AddQuestionsOptions } from '@/stores'

type FormData = {
  activeGroupIndex?: number
  questionnaire?: fhir4.Questionnaire
}

export const useFormDataStorage = () => {
  const formStore = useFormStore()

  const getFormDataFromLocalStorage = () => {
    try {
      const dataFromLocalStorage = localStorage.getItem(
        localStorageKeys.formData
      )
      if (!dataFromLocalStorage) return null
      const formData = JSON.parse(dataFromLocalStorage) as FormData
      if (!formData.questionnaire) return null
      const activeGroupIndex = z
        .number()
        .nonnegative()
        .parse(formData.activeGroupIndex)
      return {
        activeGroupIndex,
        questionnaire: formData.questionnaire,
      }
    } catch (error) {
      if (error instanceof z.ZodError) {
        console.error(error.flatten())
        return null
      } else {
        throw error
      }
    }
  }

  const getConfigurationFromLocalStorage = () => {
    const payload: Partial<CreateFormOptions> = {
      formName: '',
      sectionsCount: 0,
      additionalLanguages: [] as RootFhirLanguageCode[],
      theme: {} as ThemeColorValidationOptions,
    }
    const configurationProperties = localStorage.getItem(
      localStorageKeys.configurationProperties
    )
    if (configurationProperties) {
      const {
        formName,
        primaryLanguage,
        sectionsCount,
        additionalLanguages,
        theme,
      } = JSON.parse(configurationProperties)
      if (formName) {
        payload.formName = formName
      }
      if (primaryLanguage) {
        payload.primaryLanguage = primaryLanguage
      }
      if (sectionsCount !== null) {
        payload.sectionsCount = +sectionsCount
      }
      if (theme) {
        payload.theme = theme
      }
      if (additionalLanguages !== null) {
        const additionalLanguagesParseResult =
          createFormOptionsSchema.shape.additionalLanguages
            .unwrap()
            .safeParse(additionalLanguages)
        if (additionalLanguagesParseResult.success) {
          payload.additionalLanguages = additionalLanguagesParseResult.data
        }
      }
      return payload as CreateFormOptions
    }
  }

  const loadSavedData = () => {
    const formData = getFormDataFromLocalStorage()
    const formConfiguration = getConfigurationFromLocalStorage()
    const additionalLanguages = formConfiguration?.additionalLanguages
    const theme = formConfiguration?.theme
    if (additionalLanguages && additionalLanguages.length) {
      formStore.setAdditionalLanguages([...additionalLanguages])
    }
    if (theme) {
      formStore.setTheme(theme)
    }
    if (formData !== null) {
      const { activeGroupIndex, questionnaire } = formData
      formStore.loadSavedQuestionnaire({ questionnaire })
      formStore.setActiveQuestionGroup(activeGroupIndex)
    } else {
      getConfigurationFromLocalStorage()
      formStore.setActiveQuestionGroup(0)
    }
  }

  const saveFormConfigurationToLocalStorage = (options: CreateFormOptions) => {
    localStorage.setItem(
      localStorageKeys.configurationProperties,
      JSON.stringify(options)
    )
  }

  const saveQuestionFormToLocalStorage = (options: AddQuestionsOptions) => {
    localStorage.setItem(localStorageKeys.formData, JSON.stringify(options))
  }

  onMounted(() => {
    loadSavedData()
  })

  return {
    loadSavedData,
    saveFormConfigurationToLocalStorage,
    saveQuestionFormToLocalStorage,
    getFormDataFromLocalStorage,
  }
}
