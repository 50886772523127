import {
  type AdditionalLanguagesPayload,
  type AnswerOptionsLanguagesPayload,
  type MultiSliderLabelLanguagesPayload,
  additionalLanguagesSchema,
  answerOptionsLanguagesSchema,
  addTooltipOptionsSchema,
  addTooltipRemoveSchema,
  type EnableBehavior,
  enableBehaviorSchema,
  formQuestionSchema,
  type NumberRangeValidationOptions,
  type ValidationKey,
  type MultiSliderLabelType,
  type BaumPayloadType,
  type BaumType,
  validationKeys,
  primaryConditionSchema,
  multiSliderLabelLanguagesSchema,
} from '@/schemas'
import type { ComponentType } from '@/types'
import formUtils from '@/utils/form.utils'
import { produce } from 'immer'
import { cloneDeep } from 'lodash-es'
import { z } from 'zod'
import { v4 } from 'uuid'
import { Map } from 'immutable'
import {
  AnswerOption,
  type AnswerOptionPayload,
  answerOptionSchema,
} from './AnswerOption'
import {
  EnableWhen,
  updateEnableWhenItemsSchema,
  type AddEnableWhenItemOptions,
  type UpdateEnableWhenPayload,
} from './EnableWhen'
import { useFormStore } from '@/stores'

type QuestionConstructorOptions = {
  fhirQuestionData?: fhir4.QuestionnaireItem
}

interface QuestionnaireItem extends fhir4.QuestionnaireItem {
  primaryCondition?: boolean
}

export type FormQuestionData = {
  id: string | null
  linkId: string
  label: string
  required?: boolean
  readOnly?: boolean
  repeats?: boolean
  alignment?: string | undefined
  componentType: ComponentType | null
  helpText: string | null
  answerOptions: AnswerOption[] | undefined
  baumOptions: BaumType[]
  columnPositionIndex: number | null
  languages?: Map<string, string> | null
  toolTipLanguages?: Map<string, string> | null
  multiSliderLabelLanguages?: Map<string, MultiSliderLabelType[]> | undefined
  answerOptionsLanguages?: Map<string, AnswerOption[]> | undefined
  enableWhenData: EnableWhen[] | null
  enableBehavior: EnableBehavior | null
  primaryCondition: boolean
  phoneValidation: boolean
  emailValidation: boolean
  textValidation: boolean
  numberValidation: boolean
  numberRangeMinValue: number | null
  numberRangeMaxValue: number | null
  multiSliderLabels: fhir4.QuestionnaireItem[] | undefined
}
export type LangTextData = {
  url: string
  extension: [
    {
      url: 'lang'
      valueCode: string
    },
    {
      url: 'content'
      valueString: string
    }
  ]
}

export type AdditionalLanguagesType = 'textLanguage' | 'tooltip'
export type SetEstensionsParams = {
  additionalLanguageItem: fhir4.QuestionnaireItem
  languageExtension: LangTextData
  valueCode: string
  valueString: string
  questionnaire?: fhir4.Questionnaire
}

const updateAnswerOptionsSchema = z.object({
  answerOptions: z.instanceof(AnswerOption).array(),
})
export type UpdateAnswerOptions = z.infer<typeof updateAnswerOptionsSchema>
export type ProcessNumberRangePayload = NumberRangeValidationOptions & {
  forRemove: boolean
}

export type NumberRangeValues = {
  minValue: number | null
  maxValue: number | null
}

export class Question {
  static BaumExtensionUrl = 'https://visiontree.com/fhir/form-controls/tree'
  static SpineExtensionUrl = 'https://visiontree.com/fhir/form-controls/spine'
  static fhirStructureDefinitionUrl = 'http://hl7.org/fhir/StructureDefinition'
  static fhirValidationUrl = `${Question.fhirStructureDefinitionUrl}/questionnaire-constraint`
  static fhirMinValueExtensionUrl = `${Question.fhirStructureDefinitionUrl}/minValue`
  static fhirMaxValueExtensionUrl = `${Question.fhirStructureDefinitionUrl}/maxValue`
  static DEFAULT_QUESTION_TEMPLATE: Readonly<fhir4.QuestionnaireItem> = {
    linkId: '',
    type: 'string',
    required: false,
    repeats: false,
  }
  static BAUM_QUESTION_TEMPLATE: Readonly<fhir4.QuestionnaireItem> = {
    text: '',
    linkId: '',
    type: 'choice',
    required: false,
  }
  static HELP_TEXT_TEMPLATE: Readonly<fhir4.QuestionnaireItem> = {
    text: '',
    type: 'display',
    linkId: '',
    extension: [
      {
        url: 'http://hl7.org/fhir/StructureDefinition/questionnaire-itemControl',
        valueCodeableConcept: {
          text: 'Help-Button',
          coding: [
            {
              code: 'help',
              display: 'Help-Button',
              system: 'http://hl7.org/fhir/questionnaire-item-control',
            },
          ],
        },
      },
    ],
  }
  static DISPLAY_TEXT_TEMPLATE: Readonly<fhir4.QuestionnaireItem> = {
    text: '',
    type: 'display',
    linkId: '',
  }

  static DISPLAY_GROUP_SLIDER_TEMPLATE: Readonly<fhir4.QuestionnaireItem> = {
    text: '',
    type: 'display',
    linkId: '',
    item: [],
  }

  static MARKDOWN_TEMPLATE: Readonly<fhir4.QuestionnaireItem> = {
    linkId: '',
    type: 'display',
    text: '',
    _text: {
      extension: [
        {
          url: 'http://hl7.org/fhir/StructureDefinition/rendering-markdown',
          valueMarkdown: '',
        },
      ],
    },
  }

  static LANG_TEXT_TEMPLATE: Readonly<LangTextData> = {
    url: 'http://hl7.org/fhir/StructureDefinition/translation',
    extension: [
      {
        url: 'lang',
        valueCode: '',
      },
      {
        url: 'content',
        valueString: '',
      },
    ],
  }

  static FHIR_ALIGNMENT_EXTENSION: Readonly<fhir4.Extension> = {
    url: 'http://hl7.org/fhir/StructureDefinition/questionnaire-choiceOrientation',
    valueCode: '',
  }

  static FHIR_GROUP_SLIDER_EXTENSION: Readonly<fhir4.Extension> = {
    url: 'https://visiontree.com/fhir/questionnaire/custom-group#slider',
    valueString: '',
  }

  static FHIR_TYPE_EXTENSION: Readonly<fhir4.Extension> = {
    url: 'http://hl7.org/fhir/StructureDefinition/questionnaire-itemControl',
    valueCodeableConcept: {
      coding: [
        {
          system: 'http://hl7.org/fhir/questionnaire-item-control',
          code: '',
        },
      ],
    },
  }
  static COLUMN_POSITION_EXTENSION: Readonly<fhir4.Extension> = {
    url: formUtils.COLUMN_SIZE_URL,
    valueUnsignedInt: undefined,
  }

  private _fhirQuestionData: QuestionnaireItem | null = null

  constructor(options?: QuestionConstructorOptions) {
    if (options) {
      const { fhirQuestionData } = options
      if (fhirQuestionData) {
        this._fhirQuestionData = cloneDeep(fhirQuestionData)
      }
    }
  }

  get fhirQuestion(): fhir4.QuestionnaireItem | null {
    return this._fhirQuestionData
  }

  get helpText(): string | null {
    if (!this._fhirQuestionData?.item?.length) return null
    return (
      this._fhirQuestionData.item.find((item) => item?.linkId.endsWith('-help'))
        ?.text || null
    )
  }

  private getValidationTemplate = (key: ValidationKey): fhir4.Extension =>
    JSON.parse(`{
  "url": "${Question.fhirValidationUrl}",
  "extension": [
    {
      "url": "key",
      "valueId": "${key}"
    },
    {
      "url": "severity",
      "valueCode": "error"
    },
    {
      "url": "human",
      "valueString": "use-system-default"
    },
    {
      "url": "expression",
      "valueString": "use-system-default"
    }
  ]
}`)

  private getNumberRangeExtension(
    data: NumberRangeValidationOptions
  ): fhir4.Extension[] {
    const { maxValue, minValue, allowDecimal } = data
    const key = allowDecimal ? 'valueDecimal' : 'valueInteger'
    return JSON.parse(`[
  {
    "url": "${Question.fhirMinValueExtensionUrl}",
    "${key}": ${String(minValue)}
  },
  {
    "url": "${Question.fhirMaxValueExtensionUrl}",
    "${key}": ${String(maxValue)}
  }
]`)
  }

  private _getChoiceComponentFormType(): ComponentType | null {
    if (!this._fhirQuestionData?.extension?.length) return null
    const typeExtension = this._fhirQuestionData.extension.find(
      (extension) =>
        extension.url ===
        'http://hl7.org/fhir/StructureDefinition/questionnaire-itemControl'
    )
    if (!typeExtension?.valueCodeableConcept?.coding?.length) return null
    switch (typeExtension.valueCodeableConcept.coding[0].code) {
      case 'slider':
        return 'slider'
      case 'radio-button':
        return 'radio'
      case 'check-box':
        return 'checkbox'
      case 'toggle-switch':
        return 'toggle'
      case 'spine':
        return 'spine'
      case 'tree':
        return 'baum'
      default:
        return null
    }
  }

  private _getDisplayComponentFormType(): ComponentType | null {
    if (!this._fhirQuestionData?.linkId) return null
    const displayComponents: ComponentType[] = [
      'displayText',
      'markdown',
      'multiSlider',
    ]
    let componentType: ComponentType | null = null
    displayComponents.forEach((component) => {
      if (
        this._fhirQuestionData &&
        this._fhirQuestionData.linkId.indexOf(component) !== -1
      )
        componentType = component
    })
    return componentType
  }

  private get _formComponentType(): ComponentType | null {
    if (!this._fhirQuestionData) return null
    switch (this._fhirQuestionData.type) {
      case 'date':
        return 'date'
      case 'integer':
        return 'numericStepper'
      case 'text':
        return 'inputText'
      case 'display':
        return this._getDisplayComponentFormType()
      case 'choice':
        return this._getChoiceComponentFormType()
      default:
        return null
    }
  }

  get columnPositionIndex(): number | null {
    if (!this._fhirQuestionData || !this._fhirQuestionData.extension)
      return null

    const extensionIndex = this._fhirQuestionData.extension.find(
      (extension) => extension.url === formUtils.COLUMN_SIZE_URL
    )
    if (!extensionIndex || extensionIndex.valueUnsignedInt === undefined)
      return null
    return extensionIndex.valueUnsignedInt
  }

  get getAlignment(): string | undefined {
    if (!this._fhirQuestionData || !this._fhirQuestionData.extension)
      return undefined

    const alignmentExtension =
      this._fhirQuestionData.extension.find(
        (extension) =>
          extension.url ===
          'http://hl7.org/fhir/StructureDefinition/questionnaire-choiceOrientation'
      ) || ''
    if (!alignmentExtension || !alignmentExtension.valueCode) {
      return undefined
    }
    return alignmentExtension.valueCode
  }

  get getAnswerOption(): fhir4.QuestionnaireItemAnswerOption[] | undefined {
    if (!this._fhirQuestionData) return []
    if (this.isMultiSliderComponent) {
      if (
        this._fhirQuestionData.item &&
        this._fhirQuestionData.item.length > 0
      ) {
        return this._fhirQuestionData.item[0].answerOption
      }
    } else {
      return cloneDeep(this._fhirQuestionData.answerOption)
    }
    return []
  }

  get getReadOnly(): boolean | undefined {
    if (!this._fhirQuestionData) return undefined
    if (this.isMultiSliderComponent) {
      if (
        this._fhirQuestionData.item &&
        this._fhirQuestionData.item.length > 0
      ) {
        return this._fhirQuestionData.item[0].readOnly
      }
    } else {
      return this._fhirQuestionData.readOnly
    }
    return undefined
  }

  get getBaumOptions(): BaumType[] {
    const baumOptions: BaumType[] = []
    function getProp(
      baumItems: fhir4.QuestionnaireItem[] | undefined,
      result: BaumType[]
    ) {
      baumItems &&
        baumItems.forEach((option) => {
          if (option.item) {
            const obj = {
              text: option.text,
              item: [],
            }
            result.push(obj)
            getProp(option.item, obj.item)
          } else {
            result.push({
              text: option.text,
            })
          }
        })
    }
    getProp(this._fhirQuestionData?.item, baumOptions)
    return baumOptions
  }

  get getRequired(): boolean | undefined {
    if (!this._fhirQuestionData) return undefined
    if (this.isMultiSliderComponent) {
      if (
        this._fhirQuestionData.item &&
        this._fhirQuestionData.item.length > 0
      ) {
        return this._fhirQuestionData.item[0].required
      }
    } else {
      return this._fhirQuestionData.required
    }
    return undefined
  }

  get getRepeats(): boolean | undefined {
    if (!this._fhirQuestionData) return undefined
    if (this.isMultiSliderComponent) {
      if (
        this._fhirQuestionData.item &&
        this._fhirQuestionData.item.length > 0
      ) {
        return this._fhirQuestionData.item[0].repeats
      }
    } else {
      return this._fhirQuestionData.repeats
    }
    return undefined
  }

  get formQuestionData(): FormQuestionData | null {
    if (!this._fhirQuestionData) return null
    const answerOptions = this.getAnswerOption
    const numberRangeData = this.getNumberRangeExtensionValues()

    return {
      linkId: this._fhirQuestionData.linkId,
      label: this._fhirQuestionData.text || '',
      alignment: this.getAlignment,
      required: this.getRequired,
      repeats: this.getRepeats,
      readOnly: this.getReadOnly,
      componentType: this._formComponentType,
      helpText: this.helpText,
      baumOptions: this.getBaumOptions,
      answerOptions: answerOptions?.length
        ? answerOptions.map((answer) => new AnswerOption({ fhirData: answer }))
        : undefined,
      answerOptionsLanguages: this.getAnswerOptionsLanguages(),
      columnPositionIndex: this.columnPositionIndex,
      id: this._fhirQuestionData.id || null,
      languages: this._getAdditionalLanguagesExtension(),
      multiSliderLabelLanguages: this.getMultiSliderLabelLanguages(),
      toolTipLanguages: this._getToolTipLanguages(),
      enableWhenData: !this._fhirQuestionData.enableWhen
        ? null
        : this._fhirQuestionData.enableWhen.map(
            (enableWhen) => new EnableWhen({ fhirData: enableWhen })
          ),
      enableBehavior: this._fhirQuestionData.enableBehavior ?? null,
      primaryCondition: this._fhirQuestionData.primaryCondition ?? false,
      phoneValidation: this.isValidationFieldExist(
        this._fhirQuestionData,
        'phone'
      ),
      emailValidation: this.isValidationFieldExist(
        this._fhirQuestionData,
        'email'
      ),
      textValidation: this.isValidationFieldExist(
        this._fhirQuestionData,
        'text'
      ),
      numberValidation: this.isValidationFieldExist(
        this._fhirQuestionData,
        'numbers'
      ),
      numberRangeMinValue: numberRangeData.minValue,
      numberRangeMaxValue: numberRangeData.maxValue,
      multiSliderLabels: this._getMultiSliderLabels,
    }
  }

  private isValidationFieldExist(
    fhirData: fhir4.QuestionnaireItem,
    validationKey: ValidationKey
  ) {
    //TODO: should be optimized with only one loop iteration
    return this._findValidationExtensionIndex(fhirData, validationKey) !== -1
  }

  private _getAdditionalLanguagesExtension(): Map<string, string> | null {
    if (!this._fhirQuestionData) return null
    if (!this._fhirQuestionData._text) return null

    let languages = Map<string, string>()

    this._fhirQuestionData._text?.extension?.forEach((item) => {
      if (
        item?.extension &&
        item.extension[0].valueCode &&
        item.extension[1].valueString
      ) {
        languages = languages.set(
          item.extension[0].valueCode,
          item.extension[1].valueString
        )
      }
    })

    return languages.size > 0 ? languages : null
  }

  private getMultiSliderLabelLanguages():
    | Map<string, MultiSliderLabelType[]>
    | undefined {
    if (!this._fhirQuestionData) return undefined
    if (!this._fhirQuestionData.item) return undefined

    let languages = Map<string, MultiSliderLabelType[]>()
    const data: any = {}
    this._fhirQuestionData.item.forEach((item) => {
      if (item.type === 'choice') {
        const formStore = useFormStore()
        const additionalLanguages = formStore.additionalLanguages
        additionalLanguages.forEach((valueCode) => {
          const slider = cloneDeep(item)
          const displayExtension = item?._text?.extension?.find(
            (extension) =>
              extension.extension &&
              extension.extension[0].valueCode === valueCode
          )
          const valueString =
            (displayExtension &&
              displayExtension.extension &&
              displayExtension.extension[1].valueString) ||
            ''

          const answerOptionInstance = {
            id: slider.id,
            valueString,
          }
          if (data[valueCode]) {
            data[valueCode].push(answerOptionInstance)
          } else {
            data[valueCode] = [answerOptionInstance]
          }
        })
      }
    })

    for (const key in data) {
      languages = languages.set(key, data[key])
    }
    return languages.size > 0 ? languages : undefined
  }

  private getAnswerOptionsLanguages(): Map<string, AnswerOption[]> | undefined {
    if (!this._fhirQuestionData) return undefined
    let answerOptionsArray: fhir4.QuestionnaireItemAnswerOption[] = []
    if (this._fhirQuestionData.answerOption) {
      answerOptionsArray = this._fhirQuestionData.answerOption
    }
    if (
      this.isMultiSliderComponent &&
      this._fhirQuestionData.item &&
      this._fhirQuestionData.item[0] &&
      this._fhirQuestionData.item[0].answerOption
    ) {
      answerOptionsArray = this._fhirQuestionData.item[0].answerOption
    }
    let languages = Map<string, AnswerOption[]>()
    const data: any = {}
    answerOptionsArray.forEach((item) => {
      const formStore = useFormStore()
      const additionalLanguages = formStore.additionalLanguages
      additionalLanguages.forEach((valueCode) => {
        const answerOption = cloneDeep(item)
        const displayExtension = item.valueCoding?._display?.extension?.find(
          (extension) =>
            extension.extension &&
            extension.extension[0].valueCode === valueCode
        )
        const displayString =
          (displayExtension &&
            displayExtension.extension &&
            displayExtension.extension[1].valueString) ||
          ''
        const codeExtension = item.valueCoding?._code?.extension?.find(
          (extension) =>
            extension.extension &&
            extension.extension[0].valueCode === valueCode
        )
        const codeString =
          (codeExtension &&
            codeExtension.extension &&
            codeExtension.extension[1].valueString) ||
          ''
        if (answerOption.valueCoding) {
          answerOption.valueCoding.display = displayString
          answerOption.valueCoding.code = codeString
        }
        const answerOptionInstance = new AnswerOption({
          fhirData: answerOption,
        })
        if (data[valueCode]) {
          data[valueCode].push(answerOptionInstance)
        } else {
          data[valueCode] = [answerOptionInstance]
        }
      })
    })
    for (const key in data) {
      languages = languages.set(key, data[key])
    }
    return languages.size > 0 ? languages : undefined
  }

  private _getToolTipLanguages(): Map<string, string> | null {
    if (!this._fhirQuestionData) return null
    if (!this._fhirQuestionData.item) return null

    let languages = Map<string, string>()
    const tooltipItem = this._fhirQuestionData.item.find(
      (item) => item.type !== 'choice'
    )
    tooltipItem &&
      tooltipItem._text?.extension?.forEach((item) => {
        if (
          item?.extension &&
          item.extension[0].valueCode &&
          item.extension[1].valueString
        ) {
          languages = languages.set(
            item.extension[0].valueCode,
            item.extension[1].valueString
          )
        }
      })
    return languages.size > 0 ? languages : null
  }

  get _getMultiSliderLabels(): fhir4.QuestionnaireItem[] {
    if (
      this.isMultiSliderComponent &&
      this._fhirQuestionData &&
      this._fhirQuestionData.item &&
      this._fhirQuestionData.item.length > 0
    ) {
      return this._fhirQuestionData.item.filter((item) => {
        const itemClone = cloneDeep(item)
        const sliderExtension = itemClone.extension?.find(
          (extension) =>
            extension.url ===
            'http://hl7.org/fhir/StructureDefinition/questionnaire-itemControl'
        )
        return (
          sliderExtension &&
          sliderExtension.valueCodeableConcept &&
          sliderExtension.valueCodeableConcept.coding &&
          sliderExtension.valueCodeableConcept.coding[0].code === 'slider'
        )
      })
    }
    return []
  }

  get isMultiSliderComponent(): boolean {
    return (
      !!this._fhirQuestionData &&
      !!this._fhirQuestionData.linkId &&
      this._fhirQuestionData.linkId.indexOf('multiSlider') !== -1
    )
  }

  get isSliderComponent(): boolean {
    return (
      !!this._fhirQuestionData &&
      !!this._fhirQuestionData.linkId &&
      this._fhirQuestionData.linkId.indexOf('slider') !== -1 &&
      !this.isMultiSliderComponent
    )
  }

  get isBaumComponent(): boolean {
    return (
      !!this._fhirQuestionData &&
      !!this._fhirQuestionData.linkId &&
      this._fhirQuestionData.linkId.indexOf('baum') !== -1
    )
  }

  private _getBareQuestion(): fhir4.QuestionnaireItem {
    return cloneDeep(Question.DEFAULT_QUESTION_TEMPLATE)
  }

  private _getBaumBareQuestion(): fhir4.QuestionnaireItem {
    return cloneDeep(Question.BAUM_QUESTION_TEMPLATE)
  }

  private _getBareDisplayText(): fhir4.QuestionnaireItem {
    return cloneDeep(Question.DISPLAY_TEXT_TEMPLATE)
  }

  private _getBareGroupSlider(): fhir4.QuestionnaireItem {
    return cloneDeep(Question.DISPLAY_GROUP_SLIDER_TEMPLATE)
  }

  private _getBareMarkdown(): fhir4.QuestionnaireItem {
    return cloneDeep(Question.MARKDOWN_TEMPLATE)
  }

  private _getBareHelpText(): fhir4.QuestionnaireItem {
    return cloneDeep(Question.HELP_TEXT_TEMPLATE)
  }

  private _getBareFhirTypeExtension(): fhir4.Extension {
    return cloneDeep(Question.FHIR_TYPE_EXTENSION)
  }

  private _getBareFhirAlignmentExtension(): fhir4.Extension {
    return cloneDeep(Question.FHIR_ALIGNMENT_EXTENSION)
  }

  private _getBareFhirGroupSliderExtension(): fhir4.Extension {
    return cloneDeep(Question.FHIR_GROUP_SLIDER_EXTENSION)
  }

  private _getBareColumnPositionExtension(): fhir4.Extension {
    return cloneDeep(Question.COLUMN_POSITION_EXTENSION)
  }

  private _getBareLanguageText(): LangTextData {
    return cloneDeep(Question.LANG_TEXT_TEMPLATE)
  }

  private _getFhirTypeExtension(
    componentType:
      | 'slider'
      | 'checkbox'
      | 'radio'
      | 'toggle'
      | 'multiSlider'
      | 'spine'
      | 'baum'
  ): fhir4.Extension | null {
    const typeExtensionTemplate = this._getBareFhirTypeExtension()
    let code: string | null
    let system: string | null = null

    switch (componentType) {
      case 'checkbox':
        code = 'check-box'
        break
      case 'radio':
        code = 'radio-button'
        break
      case 'slider':
        code = 'slider'
        break
      case 'multiSlider':
        code = 'multiSlider'
        break
      case 'toggle':
        system = 'https://visiontree.com/fhir/form-controls/toggle-switch'
        code = 'toggle-switch'
        break
      case 'spine':
        system = Question.SpineExtensionUrl
        code = 'spine'
        break
      case 'baum':
        system = Question.BaumExtensionUrl
        code = 'tree'
        break
      default:
        code = null
    }
    if (!code) return null

    //Condition only for ts
    if (typeExtensionTemplate.valueCodeableConcept?.coding) {
      typeExtensionTemplate.valueCodeableConcept.coding[0].code = code
      if (system) {
        typeExtensionTemplate.valueCodeableConcept.coding[0].system = system
      }
    }

    return typeExtensionTemplate
  }

  private _getFhirQuestionType(
    formComponentType: z.infer<
      (typeof formQuestionSchema)['shape']['componentType']
    >
  ) {
    let fhirQuestionType: fhir4.QuestionnaireItem['type'] | null
    let fhirQuestionAlignmentExtension: fhir4.Extension | null = null
    let fhirQuestionTypeExtension: fhir4.Extension | null = null
    let fhirQuestionGroupSliderExtension: fhir4.Extension | null = null
    switch (formComponentType) {
      case 'date':
        fhirQuestionType = 'date'
        break
      case 'inputText':
        fhirQuestionType = 'text'
        break
      case 'numericStepper':
        fhirQuestionType = 'integer'
        break
      case 'displayText':
      case 'markdown':
        fhirQuestionType = 'display'
        break
      case 'checkbox':
      case 'radio':
        fhirQuestionType = 'choice'
        fhirQuestionAlignmentExtension = this._getBareFhirAlignmentExtension()
        fhirQuestionTypeExtension =
          this._getFhirTypeExtension(formComponentType)
        break
      case 'multiSlider':
        fhirQuestionType = 'display'
        fhirQuestionTypeExtension =
          this._getFhirTypeExtension(formComponentType)
        fhirQuestionGroupSliderExtension =
          this._getBareFhirGroupSliderExtension()
        break
      case 'slider':
      case 'toggle':
      case 'spine':
      case 'baum':
        fhirQuestionType = 'choice'
        fhirQuestionTypeExtension =
          this._getFhirTypeExtension(formComponentType)
        break
      default:
        fhirQuestionType = null
    }
    return {
      fhirQuestionType,
      fhirQuestionTypeExtension,
      fhirQuestionAlignmentExtension,
      fhirQuestionGroupSliderExtension,
    }
  }

  getQuestionDataFromTemplate(componentType: ComponentType) {
    switch (componentType) {
      case 'displayText':
        return this._getBareDisplayText()
      case 'multiSlider':
        return this._getBareGroupSlider()
      case 'markdown':
        return this._getBareMarkdown()
      case 'baum':
        return this._getBaumBareQuestion()
      default:
        return this._getBareQuestion()
    }
  }

  createQuestion(options: z.infer<typeof formQuestionSchema>) {
    const optionsParseResult = formQuestionSchema.safeParse(options)
    if (!optionsParseResult.success) {
      console.error(optionsParseResult.error)
      return this
    }
    const {
      label,
      linkId,
      required,
      componentType,
      readOnly,
      repeats,
      columnPositionIndex,
    } = optionsParseResult.data

    const {
      fhirQuestionType,
      fhirQuestionTypeExtension,
      fhirQuestionAlignmentExtension,
      fhirQuestionGroupSliderExtension,
    } = this._getFhirQuestionType(componentType)
    if (!fhirQuestionType) {
      console.error(
        `Can't create a FhirQuestion. \n Something went wrong with component type. \n Options payload: ${JSON.stringify(
          options,
          null,
          2
        )}`
      )

      return this
    }
    const componentWithAnswerOptions = ['radio', 'checkbox', 'baum']
    const componentWithoutRadioOptions = [
      'markdown',
      'multiSlider',
      'displayText',
      'baum',
    ]
    const questionDataFromTemplate =
      this.getQuestionDataFromTemplate(componentType)
    questionDataFromTemplate.linkId = linkId
    questionDataFromTemplate.text = label
    questionDataFromTemplate.type = fhirQuestionType
    questionDataFromTemplate.id = v4()
    if (componentType === 'markdown') {
      if (
        questionDataFromTemplate &&
        questionDataFromTemplate._text &&
        questionDataFromTemplate._text.extension
      ) {
        questionDataFromTemplate._text.extension[0].valueMarkdown = label
      }
    }
    if (!componentWithoutRadioOptions.includes(componentType)) {
      questionDataFromTemplate.required = required
      questionDataFromTemplate.readOnly = readOnly
      questionDataFromTemplate.repeats = repeats
    }
    if (fhirQuestionTypeExtension) {
      questionDataFromTemplate.extension = []
      questionDataFromTemplate.extension.push(fhirQuestionTypeExtension)
      if (fhirQuestionAlignmentExtension) {
        questionDataFromTemplate.extension.push(fhirQuestionAlignmentExtension)
      }
    }
    if (fhirQuestionGroupSliderExtension) {
      questionDataFromTemplate.extension = []
      fhirQuestionGroupSliderExtension.valueString =
        'group-slider#' + questionDataFromTemplate.id
      questionDataFromTemplate.extension.push(fhirQuestionGroupSliderExtension)
    }
    this._fhirQuestionData = questionDataFromTemplate
    this.setColumnPositionIndex(columnPositionIndex)
    if (componentType === 'spine') {
      for (let index = 0; index < 8; index++) {
        this.addAnswerOption({ displayedText: `C${index}`, code: `C${index}` })
      }
      for (let index = 1; index <= 13; index++) {
        this.addAnswerOption({ displayedText: `T${index}`, code: `T${index}` })
      }
      for (let index = 1; index <= 5; index++) {
        this.addAnswerOption({ displayedText: `L${index}`, code: `L${index}` })
      }
      for (let index = 1; index <= 5; index++) {
        this.addAnswerOption({ displayedText: `S${index}`, code: `S${index}` })
      }
      this.addAnswerOption({ displayedText: 'Becken', code: 'Backen' })
    }
    if (componentType === 'toggle') {
      this.addAnswerOption({
        displayedText: 'Option 1',
        code: 'code:Option 1',
      })
      this.addAnswerOption({
        displayedText: 'Option 2',
        code: 'code:Option 2',
      })
    }
    if (componentWithAnswerOptions.includes(componentType)) {
      this.addAnswerOption({ displayedText: '', code: '' })
    }

    return this
  }

  addTooltip(options: z.infer<typeof addTooltipOptionsSchema>) {
    if (!this._fhirQuestionData) {
      throw new Error('You need to create a FHIR Question')
    }
    const validationResult = addTooltipOptionsSchema.safeParse(options)
    if (!validationResult.success) {
      console.error(validationResult.error)
      return this
    }
    const { questionLinkId, tooltipValue } = validationResult.data

    const bareHelpTextObject = this._getBareHelpText()
    bareHelpTextObject.linkId = `${questionLinkId}-help`
    bareHelpTextObject.text = tooltipValue

    this._fhirQuestionData = produce(
      cloneDeep(this._fhirQuestionData),
      (draft) => {
        if (!draft.item) {
          draft.item = []
        }
        const existHelpTextItemIndex = draft.item.findIndex((item) =>
          item?.linkId.endsWith('-help')
        )
        existHelpTextItemIndex === -1
          ? draft.item.push({ ...bareHelpTextObject })
          : (draft.item[existHelpTextItemIndex] = { ...bareHelpTextObject })
      }
    )
    return this
  }

  removeTooltop(option: z.infer<typeof addTooltipRemoveSchema>) {
    if (!this._fhirQuestionData) {
      throw new Error('You need to create a FHIR Question')
    }
    const validationResult = addTooltipRemoveSchema.safeParse(option)
    if (!validationResult.success) {
      console.error(validationResult.error)
      return this
    }

    const { questionLinkId } = validationResult.data

    this._fhirQuestionData = produce(this._fhirQuestionData, (draft) => {
      if (!draft.item) {
        draft.item = []
      }
      const existHelpTextItemIndex = draft.item.findIndex((item) =>
        item?.linkId.endsWith(questionLinkId + '-help')
      )
      if (existHelpTextItemIndex !== -1) {
        draft.item.splice(existHelpTextItemIndex, 1)
      }
    })
  }

  updateLinkId(newLinkId: string) {
    if (newLinkId.trim() && this._fhirQuestionData !== null) {
      this._fhirQuestionData = produce(this._fhirQuestionData, (draft) => {
        draft.linkId = newLinkId
      })
    }
  }

  updateText(newText: string) {
    if (this._fhirQuestionData !== null) {
      this._fhirQuestionData = produce(this._fhirQuestionData, (draft) => {
        draft.text = newText
        if (draft._text && draft._text.extension) {
          const valueMarkdown = draft._text.extension.find(
            (extension) =>
              extension.url ===
              'http://hl7.org/fhir/StructureDefinition/rendering-markdown'
          )
          valueMarkdown && (draft._text.extension[0].valueMarkdown = newText)
        }
      })
    }
  }

  updateAlignment(newAlignment: string | undefined) {
    if (this._fhirQuestionData === null) return undefined
    const alignmentExtension = this._getBareFhirAlignmentExtension()
    alignmentExtension.valueCode = newAlignment

    this._fhirQuestionData = produce(
      cloneDeep(this._fhirQuestionData),
      (draft) => {
        if (!draft?.extension) {
          draft.extension = []
        }
        const existExtensionIndex = draft.extension.findIndex(
          (extension) =>
            extension.url ===
            'http://hl7.org/fhir/StructureDefinition/questionnaire-choiceOrientation'
        )

        existExtensionIndex !== -1
          ? (draft.extension[existExtensionIndex] = {
              ...alignmentExtension,
            })
          : draft.extension.push({ ...alignmentExtension })
      }
    )
  }

  updateRequired(newRequired: boolean) {
    if (this._fhirQuestionData !== null) {
      this._fhirQuestionData = produce(this._fhirQuestionData, (draft) => {
        if (this.isMultiSliderComponent) {
          draft.item = cloneDeep(draft?.item)?.map((item) => {
            return {
              ...item,
              required: newRequired,
            }
          })
        } else {
          draft.required = newRequired
        }
      })
    }
  }

  updateReadOnly(newReadOnly: boolean) {
    if (this._fhirQuestionData !== null) {
      this._fhirQuestionData = produce(this._fhirQuestionData, (draft) => {
        if (this.isMultiSliderComponent) {
          draft.item = cloneDeep(draft?.item)?.map((item) => {
            return {
              ...item,
              readOnly: newReadOnly,
            }
          })
        } else {
          draft.readOnly = newReadOnly
        }
      })
    }
  }

  updateRepeats(newRepeats: boolean) {
    if (this._fhirQuestionData !== null) {
      this._fhirQuestionData = produce(this._fhirQuestionData, (draft) => {
        if (this.isMultiSliderComponent) {
          draft.item = cloneDeep(draft?.item)?.map((item) => {
            return {
              ...item,
              repeats: newRepeats,
            }
          })
        } else {
          draft.repeats = newRepeats
        }
      })
    }
  }

  removeBaumItem(componentIndex = '') {
    if (this._fhirQuestionData === null) {
      return console.warn('You need to create a question')
    }

    this._fhirQuestionData = produce(
      cloneDeep(this._fhirQuestionData),
      (draft) => {
        const targetBaumItem = this.getTargetBaumItem(draft, componentIndex)
        if (componentIndex.indexOf('.') === -1) {
          draft.answerOption &&
            draft.answerOption.splice(parseInt(componentIndex), 1)
          draft.item =
            (draft.item &&
              draft.item.filter(
                (item) => item.linkId !== targetBaumItem.linkId
              )) ||
            []
        } else {
          const indexArray = componentIndex.split('.')
          const lastIndex = indexArray.pop()
          const parentComponentIndex = indexArray.join('.')
          const parentBaumItem = this.getTargetBaumItem(
            draft,
            parentComponentIndex
          )
          if (typeof lastIndex === 'string') {
            parentBaumItem.answerOption &&
              parentBaumItem.answerOption.splice(parseInt(lastIndex), 1)
          }
          parentBaumItem.item =
            (parentBaumItem.item &&
              parentBaumItem.item.filter(
                (item) => item.linkId !== targetBaumItem.linkId
              )) ||
            []
        }
      }
    )
  }

  updateBaumItem(payload: BaumPayloadType) {
    if (this._fhirQuestionData === null) {
      return console.warn('You need to create a question')
    }

    this._fhirQuestionData = produce(
      cloneDeep(this._fhirQuestionData),
      (draft) => {
        const indexArray = payload.componentIndex.split('.')
        const lastIndex = indexArray.pop()
        const parentComponentIndex = indexArray.join('.')
        const parentBaumItem = this.getTargetBaumItem(
          draft,
          parentComponentIndex
        )
        const targetBaumItem = this.getTargetBaumItem(
          draft,
          payload.componentIndex
        )

        const targetEnableWhen =
          targetBaumItem.enableWhen &&
          targetBaumItem.enableWhen?.find(
            (enableWhen) => enableWhen.question === parentBaumItem.linkId
          )

        if (
          targetEnableWhen &&
          targetEnableWhen.answerCoding &&
          targetEnableWhen.answerCoding.code
        ) {
          targetEnableWhen.answerCoding.code = payload.text
        }
        targetBaumItem.text = payload.text
        if (payload.componentIndex.indexOf('.') === -1) {
          const targetAnswerOption =
            draft.answerOption &&
            draft.answerOption[parseInt(payload.componentIndex)]
          if (targetAnswerOption && targetAnswerOption.valueCoding) {
            targetAnswerOption.valueCoding.display = payload.text
            targetAnswerOption.valueCoding.code = payload.text
          }
        } else {
          if (typeof lastIndex === 'string') {
            const targetAnswerOption =
              parentBaumItem.answerOption &&
              parentBaumItem.answerOption[parseInt(lastIndex)]
            if (targetAnswerOption && targetAnswerOption.valueCoding) {
              targetAnswerOption.valueCoding.display = payload.text
              targetAnswerOption.valueCoding.code = payload.text
            }
          }
        }
      }
    )
  }

  addAnswerOption(options: AnswerOptionPayload, componentIndex = '') {
    if (this._fhirQuestionData === null) {
      return console.warn('You need to create a question')
    }

    if (
      this._fhirQuestionData.type !== 'choice' &&
      !this.isMultiSliderComponent
    ) {
      return console.warn(
        'Question is not a type choice and is not a multiSlider component'
      )
    }
    const parseResult = answerOptionSchema.safeParse(options)
    if (!parseResult.success) {
      return console.error(parseResult.error.flatten())
    }
    const answerOption = new AnswerOption().createAnswerOption(parseResult.data)

    this._fhirQuestionData = produce(
      cloneDeep(this._fhirQuestionData),
      (draft) => {
        if (!answerOption.fhirAnswerOption) {
          return console.warn("Can't create answer option")
        }
        const cloneAnswerOption = cloneDeep(answerOption.fhirAnswerOption)
        if (this.isMultiSliderComponent) {
          if (draft.item && draft.item.length > 0) {
            draft.item.forEach((item) => {
              if (!item.answerOption) {
                item.answerOption = []
              }
              if (cloneAnswerOption && cloneAnswerOption.valueCoding) {
                cloneAnswerOption.valueCoding.display = (
                  item.answerOption.length + 1
                ).toString()
                cloneAnswerOption.valueCoding.code =
                  cloneAnswerOption.valueCoding.display
              }
              item.answerOption.push(cloneAnswerOption)
            })
          }
        } else if (this.isBaumComponent) {
          this.addBaumItem(draft, cloneAnswerOption, componentIndex)
        } else {
          if (!draft.answerOption) {
            draft.answerOption = []
          }
          if (
            cloneAnswerOption &&
            cloneAnswerOption.valueCoding &&
            !cloneAnswerOption.valueCoding.display
          ) {
            let displayValue = (draft.answerOption.length + 1).toString()
            if (!this.isSliderComponent) {
              displayValue = 'Answer Option ' + displayValue
            }
            cloneAnswerOption.valueCoding.display = displayValue
            cloneAnswerOption.valueCoding.code = displayValue
          }

          draft.answerOption.push(cloneAnswerOption)
        }
      }
    )
  }

  getBaumTemplate(componentIndex: string) {
    const formStore = useFormStore()
    const activeQuestionGroup = formStore.activeQuestionGroup
    const activeQuestionGroupTitle = formStore.activeQuestionGroupTitle
    const componentType = 'baum'
    const index = Math.floor(Math.random() * 100)
    const linkId = `${String(
      activeQuestionGroup?.linkId
    )}/${activeQuestionGroupTitle}/${componentType}-${index}${
      componentIndex ? `.${componentIndex}` : ''
    }`
    const baumItem = this._getBaumBareQuestion()
    const baumExtension = this._getFhirTypeExtension('baum')
    baumItem.linkId = linkId
    baumItem.id = v4()
    if (baumExtension) {
      baumItem.extension = []
      baumItem.extension.push(baumExtension)
    }
    return baumItem
  }

  getTargetBaumItem(draft: fhir4.QuestionnaireItem, componentIndex: string) {
    const indexArray = componentIndex.split('.')
    let targetItem = draft
    indexArray &&
      indexArray.forEach((index) => {
        if (targetItem.item && targetItem.item[parseInt(index)]) {
          targetItem = targetItem.item[parseInt(index)]
        }
      })
    return targetItem
  }

  addBaumItem(
    draft: fhir4.QuestionnaireItem,
    answerOption: fhir4.QuestionnaireItemAnswerOption,
    componentIndex = ''
  ) {
    const baumItem = this.getBaumTemplate(componentIndex)
    let targetItem = null
    const createBaumItem = (
      targetItem: fhir4.QuestionnaireItem,
      item: fhir4.QuestionnaireItem,
      answerOptionItem: fhir4.QuestionnaireItemAnswerOption
    ) => {
      const enableWhenOption: AddEnableWhenItemOptions = {
        conditionalValue: '',
        operator: 'exists',
        questionLinkId: '',
      }
      const enableWhenItem = new EnableWhen().addEnableWhenItem(
        enableWhenOption
      )

      if (!targetItem.answerOption) {
        targetItem.answerOption = []
      }
      targetItem.answerOption.push(answerOptionItem)

      if (!targetItem.item) {
        targetItem.item = []
      }

      if (enableWhenItem) {
        if (!item.enableWhen) {
          item.enableWhen = []
        }
        enableWhenItem.question = targetItem.linkId
        enableWhenItem.answerCoding &&
          (enableWhenItem.answerCoding.code = item.text)

        item.enableWhen.push(enableWhenItem)
      }

      targetItem.item.push(item)
    }
    if (!componentIndex) {
      if (answerOption && answerOption.valueCoding) {
        answerOption.valueCoding.display = 'Top-level Node'
        answerOption.valueCoding.code = 'Top-level Node'
      }
      baumItem.text = 'Top-level Node'
      createBaumItem(draft, baumItem, answerOption)
    } else {
      if (answerOption && answerOption.valueCoding) {
        answerOption.valueCoding.display = 'Sub Node'
        answerOption.valueCoding.code = 'Sub Node'
      }
      baumItem.text = 'Sub Node'

      if (componentIndex.indexOf('.') === -1 && draft.item) {
        const itemIndex = parseInt(componentIndex)
        targetItem = draft.item[itemIndex]
        createBaumItem(targetItem, baumItem, answerOption)
      } else {
        targetItem = this.getTargetBaumItem(draft, componentIndex)
        createBaumItem(targetItem, baumItem, answerOption)
      }
    }
  }

  updateSliderLabel(payload: fhir4.QuestionnaireItem) {
    if (this._fhirQuestionData === null) {
      return console.warn('You need to create a question')
    }

    if (!this.isMultiSliderComponent) {
      return console.warn('Question is not a multiSlider component')
    }

    this._fhirQuestionData = produce(
      cloneDeep(this._fhirQuestionData),
      (draft) => {
        if (draft.item && draft.item.length > 0) {
          const currentSlider = draft.item.find(
            (item) => item.id === payload.id
          )
          currentSlider && (currentSlider.text = payload.text)
        }
      }
    )
  }
  removeSliderLabel(index: number) {
    if (this._fhirQuestionData === null) {
      return console.warn('You need to create a question')
    }

    if (!this.isMultiSliderComponent) {
      return console.warn('Question is not a multiSlider component')
    }
    this._fhirQuestionData = produce(
      cloneDeep(this._fhirQuestionData),
      (draft) => {
        if (draft.item && draft.item.length > 0) {
          draft.item.splice(index, 1)
        }
      }
    )
  }
  addSliderLabel(payload: fhir4.QuestionnaireItem) {
    if (this._fhirQuestionData === null) {
      return console.warn('You need to create a question')
    }

    if (!this.isMultiSliderComponent) {
      return console.warn('Question is not a multiSlider component')
    }
    this._fhirQuestionData = produce(
      cloneDeep(this._fhirQuestionData),
      (draft) => {
        if (!draft.item) {
          draft.item = []
        }
        payload = cloneDeep(payload)
        payload.linkId = payload.linkId + '-' + draft.item.length
        payload.extension =
          payload.extension &&
          payload.extension.map((extension: any) => {
            if (
              draft.item &&
              extension.url ===
                'https://visiontree.com/fhir/questionnaire/layout#column-size'
            ) {
              extension.valueUnsignedInt = draft.item.length
            }
            return extension
          })
        if (draft.item && draft.item.length > 0) {
          if (draft.item[0].answerOption) {
            payload.answerOption = draft.item[0].answerOption
          }
          payload.required = draft.item[0].required
          payload.readOnly = draft.item[0].readOnly
          payload.repeats = draft.item[0].repeats
        }
        const groupSliderExtension = this._getBareFhirGroupSliderExtension()
        groupSliderExtension.valueString = 'group-slider#' + draft.id
        if (!payload.extension) {
          payload.extension = []
        }
        payload.extension.push(groupSliderExtension)
        draft.item.push(cloneDeep(payload))
      }
    )
  }

  updateAnswerOptions(options: UpdateAnswerOptions) {
    if (!this._fhirQuestionData) {
      return console.warn('You need to create a question')
    }
    const optionsParseResult = updateAnswerOptionsSchema.safeParse(options)
    if (!optionsParseResult.success) {
      return console.error(optionsParseResult.error.flatten())
    }
    const { answerOptions } = optionsParseResult.data
    const fhirAnswerOptions = answerOptions
      .map((option) => option.fhirAnswerOption)
      .filter(
        (option): option is fhir4.QuestionnaireItemAnswerOption => !!option
      )

    this._fhirQuestionData = produce(
      cloneDeep(this._fhirQuestionData),
      (draft) => {
        if (this.isMultiSliderComponent) {
          if (draft.item && draft.item.length > 0) {
            draft.item.forEach((item) => {
              if (!item.answerOption) {
                item.answerOption = []
              }
              item.answerOption = [...cloneDeep(fhirAnswerOptions)]
            })
          }
        } else {
          if (!draft.answerOption) {
            draft.answerOption = []
          }
          draft.answerOption = [...cloneDeep(fhirAnswerOptions)]
        }
      }
    )
  }

  setColumnPositionIndex(columnPositionIndex: number) {
    if (this._fhirQuestionData === null) return
    const parseResult =
      formQuestionSchema.shape.columnPositionIndex.safeParse(
        columnPositionIndex
      )
    if (!parseResult.success) return console.error(parseResult.error.flatten())
    const columnIndex = parseResult.data

    const columnPositionExtension = this._getBareColumnPositionExtension()
    columnPositionExtension.valueUnsignedInt = columnIndex

    this._fhirQuestionData = produce(this._fhirQuestionData, (draft) => {
      if (!draft?.extension) {
        draft.extension = []
      }
      const existExtensionIndex = draft.extension.findIndex(
        (extension) => extension.url === formUtils.COLUMN_SIZE_URL
      )

      existExtensionIndex !== -1
        ? (draft.extension[existExtensionIndex] = {
            ...columnPositionExtension,
          })
        : draft.extension.push({ ...columnPositionExtension })
    })
  }

  setOptionExtension(
    option: fhir4.QuestionnaireItemAnswerOption,
    type: string,
    payload: AnswerOptionsLanguagesPayload
  ) {
    const parseResult = answerOptionsLanguagesSchema.safeParse(payload)
    if (!parseResult.success) return console.error(parseResult.error.flatten())
    const { valueCode, answerOptions } = parseResult.data
    const fhirAnswerOptions = answerOptions
      .map((option) => option.fhirAnswerOption)
      .filter(
        (option): option is fhir4.QuestionnaireItemAnswerOption => !!option
      )
    const bareLanguageText = fhirAnswerOptions.map((option) => {
      const languageExtension = this._getBareLanguageText()
      const string =
        type === 'display'
          ? option?.valueCoding?.display
          : option?.valueCoding?.code
      languageExtension.extension[0].valueCode = valueCode
      languageExtension.extension[1].valueString = string ?? ''
      return {
        id: option.id,
        languageExtension,
      }
    })

    const bareLanguageData = bareLanguageText?.find(
      (item) => item?.id === option?.id
    )
    const valueString =
      bareLanguageData &&
      bareLanguageData.languageExtension.extension[1].valueString
    const extension =
      (type === 'display'
        ? option?.valueCoding?._display?.extension
        : option?.valueCoding?._code?.extension) || []
    const alreadyExistingLanguage = extension.find(
      (item) => item.extension && item?.extension[0].valueCode === valueCode
    )
    if (alreadyExistingLanguage && alreadyExistingLanguage.extension) {
      alreadyExistingLanguage.extension[1].valueString = valueString
    } else {
      bareLanguageData && extension.push(bareLanguageData.languageExtension)
    }
    return extension
  }

  updateMultiSliderLabelsLanguage(payload: MultiSliderLabelLanguagesPayload) {
    if (this._fhirQuestionData === null) return
    const parseResult = multiSliderLabelLanguagesSchema.safeParse(payload)
    if (!parseResult.success) return console.error(parseResult.error.flatten())
    const { valueCode, sliders } = parseResult.data
    this._fhirQuestionData = produce(
      cloneDeep(this._fhirQuestionData),
      (draft) => {
        draft.item &&
          draft.item.forEach((item) => {
            if (item.type === 'choice') {
              const languageExtension = this._getBareLanguageText()
              const targetSlider = sliders.find(
                (slider) => slider.id === item.id
              )
              const valueString =
                (targetSlider && targetSlider.valueString) || ''
              languageExtension.extension[0].valueCode = valueCode
              languageExtension.extension[1].valueString = valueString
              this.setExtensionAndLanguages({
                additionalLanguageItem: item,
                languageExtension,
                valueCode,
                valueString,
              })
            }
          })
      }
    )
  }

  updateAnswerOptionsLanguage(payload: AnswerOptionsLanguagesPayload) {
    if (this._fhirQuestionData === null) return
    this._fhirQuestionData = produce(
      cloneDeep(this._fhirQuestionData),
      (draft) => {
        const mappedOption = (option: fhir4.QuestionnaireItemAnswerOption) => {
          const codeExtension = this.setOptionExtension(option, 'code', payload)
          const displayExtension = this.setOptionExtension(
            option,
            'display',
            payload
          )
          return {
            ...option,
            valueCoding: {
              ...option.valueCoding,
              _display: {
                extension: displayExtension || [],
              },
              _code: {
                extension: codeExtension || [],
              },
            },
          }
        }
        if (this.isMultiSliderComponent) {
          if (draft.item && draft.item.length > 0) {
            draft.item.forEach((item) => {
              if (!item.answerOption) {
                item.answerOption = []
              }
              item.answerOption = item.answerOption.map((option) => {
                return mappedOption(option)
              })
            })
          }
        } else {
          if (!draft.answerOption) {
            draft.answerOption = []
          }
          draft.answerOption = draft.answerOption.map((option) => {
            return mappedOption(option)
          })
        }
      }
    )
  }

  updateAdditionalLanguages(
    options: AdditionalLanguagesPayload,
    type: AdditionalLanguagesType
  ) {
    if (this._fhirQuestionData === null) return

    const parseResult = additionalLanguagesSchema.safeParse(options)
    if (!parseResult.success) return console.error(parseResult.error.flatten())
    const { valueCode, valueString } = parseResult.data

    const languageExtension = this._getBareLanguageText()
    languageExtension.extension[0].valueCode = valueCode
    languageExtension.extension[1].valueString = valueString

    this._fhirQuestionData = produce(
      cloneDeep(this._fhirQuestionData),
      (draft) => {
        const tooltipItem =
          draft.item && draft.item.find((item) => item.type !== 'choice')
        switch (type) {
          case 'tooltip':
            if (tooltipItem) {
              this.setExtensionAndLanguages({
                additionalLanguageItem: tooltipItem,
                languageExtension,
                valueCode,
                valueString,
              })
            }
            break
          case 'textLanguage':
            this.setExtensionAndLanguages({
              additionalLanguageItem: draft,
              languageExtension,
              valueCode,
              valueString,
            })
            break
        }
      }
    )
  }

  setExtensionAndLanguages(params: SetEstensionsParams) {
    if (!params.additionalLanguageItem?._text) {
      params.additionalLanguageItem._text = { extension: [] }
    }
    const alreadyExistingLanguage =
      params.additionalLanguageItem?._text.extension?.find((item) => {
        if (item?.extension?.length) {
          return item.extension[0].valueCode === params.valueCode
        }
      })
    if (alreadyExistingLanguage && alreadyExistingLanguage.extension) {
      alreadyExistingLanguage.extension[1].valueString = params.valueString
      return
    }
    params.additionalLanguageItem?._text.extension?.push(
      params.languageExtension
    )
  }

  setPrimaryCondition(value: boolean): fhir4.QuestionnaireItem | null {
    if (this._fhirQuestionData === null) {
      console.error('No question data')
      return null
    }

    const parseResult = primaryConditionSchema.safeParse(value)
    if (!parseResult.success) {
      console.error(parseResult.error.flatten())
      return null
    }
    const primaryConditionValue = parseResult.data

    this._fhirQuestionData = produce(
      cloneDeep(this._fhirQuestionData),
      (draft) => {
        draft.primaryCondition = primaryConditionValue
      }
    )
    return this._fhirQuestionData
  }

  setEnableBehavior(value: EnableBehavior): fhir4.QuestionnaireItem | null {
    if (this._fhirQuestionData === null) {
      console.error('No question data')
      return null
    }
    const parseResult = enableBehaviorSchema.safeParse(value)
    if (!parseResult.success) {
      console.error(parseResult.error.flatten())
      return null
    }
    const enableBehaviorValue = parseResult.data

    this._fhirQuestionData = produce(
      cloneDeep(this._fhirQuestionData),
      (draft) => {
        draft.enableBehavior = enableBehaviorValue
      }
    )
    return this._fhirQuestionData
  }

  addEnableWhenItem(options: AddEnableWhenItemOptions) {
    if (this._fhirQuestionData === null) {
      console.error('No question data')
      return null
    }
    this._fhirQuestionData = produce(
      cloneDeep(this._fhirQuestionData),
      (draft) => {
        if (draft.enableWhen === undefined) {
          draft.enableWhen = []
        }
        const createdEnableWhenItem = new EnableWhen().addEnableWhenItem(
          options
        )
        createdEnableWhenItem !== null
          ? draft.enableWhen.push({ ...createdEnableWhenItem })
          : console.error('Can not create enable when object')
      }
    )
    return this._fhirQuestionData
  }

  updateEnableWhenItems(payload: UpdateEnableWhenPayload) {
    if (this._fhirQuestionData === null) {
      console.error('No question data')
      return
    }
    const payloadParseResult = updateEnableWhenItemsSchema.safeParse(payload)
    if (!payloadParseResult.success) {
      return console.error(payloadParseResult.error.flatten())
    }
    const enableWhenItems = payloadParseResult.data
    this._fhirQuestionData = produce(
      cloneDeep(this._fhirQuestionData),
      (draft) => {
        draft.enableWhen = enableWhenItems
          .map((enableWhen) => enableWhen.enableWhenFhir)
          .filter(
            (item): item is fhir4.QuestionnaireItemEnableWhen => item !== null
          )
      }
    )
  }

  processValidationExtension(validationKey: ValidationKey, forRemove = false) {
    if (this._fhirQuestionData === null) {
      return console.error('No question data')
    }
    const validationKeyParseResult = validationKeys.safeParse(validationKey)
    if (!validationKeyParseResult.success) {
      return console.error(validationKeyParseResult.error.format())
    }
    forRemove
      ? this.removeValidationExtension(validationKey)
      : this.addValidationExtension(validationKey)
  }

  private _findValidationExtensionIndex(
    fhirData: fhir4.QuestionnaireItem,
    validationKey: ValidationKey
  ): number {
    if (!fhirData.extension || !fhirData.extension.length) return -1
    const extensionIndex = fhirData.extension?.findIndex(
      (extension) =>
        extension.url === Question.fhirValidationUrl &&
        extension?.extension?.find(
          (nestedExtension) => nestedExtension.valueId === validationKey
        )
    )
    return extensionIndex || -1
  }

  private addValidationExtension(validationKey: ValidationKey) {
    if (this._fhirQuestionData === null) return
    this._fhirQuestionData = produce(
      cloneDeep(this._fhirQuestionData),
      (draft) => {
        if (!draft.extension) {
          draft.extension = []
        }
        if (
          draft.extension &&
          !this.isValidationFieldExist(draft, validationKey)
        ) {
          draft.extension.push(this.getValidationTemplate(validationKey))
        }
      }
    )
  }

  private removeValidationExtension(validationKey: ValidationKey) {
    if (this._fhirQuestionData === null) return
    if (this._fhirQuestionData.extension === undefined) {
      console.error('Extension already empty')
      return
    }
    this._fhirQuestionData = produce(
      cloneDeep(this._fhirQuestionData),
      (draft) => {
        if (!draft.extension) return
        const extensionIndex = this._findValidationExtensionIndex(
          draft,
          validationKey
        )
        if (extensionIndex !== -1) {
          draft.extension.splice(extensionIndex, 1)
        }
      }
    )
  }

  processNumberRangeValidation(options: ProcessNumberRangePayload) {
    if (this._fhirQuestionData === null) {
      return
    }
    const { forRemove, ...data } = options
    forRemove
      ? this.removeNumberRangeValidationExtension()
      : this.addNumberRangeValidationExtension(data)
  }

  private numberRangeExtensionFieldIndexes(): {
    minExtensionIndex: number
    maxExtensionIndex: number
  } {
    if (
      this._fhirQuestionData === null ||
      !this._fhirQuestionData.extension ||
      !this._fhirQuestionData.extension.length
    )
      return { minExtensionIndex: -1, maxExtensionIndex: -1 }

    const findExtensionIndex = (url: string): number =>
      this?._fhirQuestionData?.extension?.findIndex(
        (extension) => extension.url === url
      ) ?? -1

    return {
      minExtensionIndex: findExtensionIndex(Question.fhirMinValueExtensionUrl),
      maxExtensionIndex: findExtensionIndex(Question.fhirMaxValueExtensionUrl),
    }
  }

  private getNumberRangeExtensionValues(): NumberRangeValues {
    if (
      this._fhirQuestionData === null ||
      !this._fhirQuestionData.extension ||
      !this._fhirQuestionData.extension.length
    )
      return { minValue: null, maxValue: null }
    const fhirExtension = this._fhirQuestionData.extension
    const { minExtensionIndex, maxExtensionIndex } =
      this.numberRangeExtensionFieldIndexes()
    return {
      minValue:
        minExtensionIndex === -1
          ? null
          : (fhirExtension.at(minExtensionIndex)?.valueInteger ||
              fhirExtension.at(minExtensionIndex)?.valueDecimal) ??
            null,
      maxValue:
        maxExtensionIndex === -1
          ? null
          : (fhirExtension.at(maxExtensionIndex)?.valueInteger ||
              fhirExtension.at(maxExtensionIndex)?.valueDecimal) ??
            null,
    }
  }

  private addNumberRangeValidationExtension(
    payload: NumberRangeValidationOptions
  ) {
    if (this._fhirQuestionData === null) return
    this._fhirQuestionData = produce(
      cloneDeep(this._fhirQuestionData),
      (draft) => {
        const numberRangeExtension = this.getNumberRangeExtension(payload)
        const [minExtension, maxExtension] = numberRangeExtension
        if (!draft.extension) {
          draft.extension = []
          draft.extension.push(...numberRangeExtension)
          return
        }
        const { minExtensionIndex, maxExtensionIndex } =
          this.numberRangeExtensionFieldIndexes()

        if (minExtensionIndex === -1) draft.extension.push({ ...minExtension })
        else draft.extension[minExtensionIndex] = { ...minExtension }

        if (maxExtensionIndex === -1) draft.extension.push({ ...maxExtension })
        else draft.extension[maxExtensionIndex] = { ...maxExtension }
      }
    )
  }

  private removeNumberRangeValidationExtension() {
    if (
      this._fhirQuestionData === null ||
      !this._fhirQuestionData?.extension ||
      !this._fhirQuestionData?.extension.length
    ) {
      return
    }
  }
}
